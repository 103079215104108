import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';


import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled, { useTheme } from 'styled-components'
import axios from 'axios';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import Write from './Write';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//excel
import { CSVLink, CSVDownload } from 'react-csv';



const Block = styled.div`
  overflow: auto;

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

  .overScr{
    min-width: 1024px;
    height: 100%;
    
    overflow-x: auto;
  }

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      
  },
  border:{
    border: 'solid'
  }
  

 
  
}



Modal.setAppElement('#root')
const List = ({match, refCategory}) => {

  //console.log(match.params)
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)
  const [refHeader, setRefHeader] = useState(null)
  const [xlData, setXlData] = useState([])

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    
    const refDivision = match.params.division
    //console.log(refDivision)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [modyOn,setModyOn] = useState(false)
    const [readOn,setReadOn] = useState(false)
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)


    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const [form, setForm] = useState ({
    
  })

  

  const onChangeComment= useCallback((name,comment) => {
    
    //console.log(name)
    const nextForm = {
      ...form,
      [name]: comment
    };

    
    setForm(nextForm);
   
    //console.log(form)
    
},[form]);


  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        if(refData.queryData[0].division == refDivision){
          return true
        }
        
      }
    })
    //console.log(startDate)
    
    //console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  

    const [ tableIndex, setTableIndex ] = useState(null)

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);


  

  useEffect(() => {
    //console.log(cookies.auth)
    fetchGetData();
    //fetchLoginCheck();
    //fetchData();
    

  },[])

  


  

  const fetchGetData = async() => {

    try{


      const response = await axios.get(
        `${process.env.REACT_APP_SOCKET}/firecheck`,{
            headers:{
                token : cookie.auth
            }
          }
      );

      const filterData = response.data.filter(refData => {
      
        if(refData.queryData[0].equipname == refCategory && refData.queryData[0].division == refDivision){
          return true
        }
      })

      setDatas(filterData.slice((page-1)*pagelimit,(page*pagelimit)))
      //setRefDatas(response.data)
      setRefDatas(filterData)
      setTotalCount(filterData.length)
      //console.log(filterData)


      // excel data 마들기 
      const sampleDatas =[]
      filterData.map((fdata,index) => {
        //console.log(fdata.queryData[0].site)
        if(refCategory == "소화기"){
          const sampleData = { 
            기기 : refCategory,
            장소 : fdata.queryData[0].site, 
            부서: fdata.queryData[0].division,
            관리번호 : fdata.queryData[0].managenumber,
            위치 : fdata.queryData[0].location,
            세부위치 : fdata.queryData[0].dlocation,
            타입 : fdata.queryData[0].type,
            압력상태 : fdata.bodyData[0].list11,
            노즐상태 : fdata.bodyData[0].list21,
            안전표지 : fdata.bodyData[0].list31,
            외관상태 : fdata.bodyData[0].list41,
            박스상태 : fdata.bodyData[0].list51,
            특이사항 : fdata.bodyData[0].comment,
            점검일 : fdata.createdAt.split("T")[0],
            점검자 : fdata.bodyData[0].writer,
            

  
          }
          sampleDatas.push(sampleData)
  

        }else if(refCategory=="세안기"){
          const sampleData = { 
            기기 : refCategory,
            장소 : fdata.queryData[0].site, 
            부서: fdata.queryData[0].division,
            관리번호 : fdata.queryData[0].managenumber,
            위치 : fdata.queryData[0].location,
            세부위치 : fdata.queryData[0].dlocation,
            보온상태 : fdata.bodyData[0].list11,
            Leak부식 : fdata.bodyData[0].list21,
            ES작동_Hand : fdata.bodyData[0].list31,
            ES작동_Foot : fdata.bodyData[0].list41,
            BS작동 : fdata.bodyData[0].list51,
            표지판상태 : fdata.bodyData[0].list61,
            특이사항 : fdata.bodyData[0].comment,
            점검일 : fdata.createdAt.split("T")[0],
            점검자 : fdata.bodyData[0].writer,

  
          }
          sampleDatas.push(sampleData)
  

        }else if(refCategory=="소화전"){
          const sampleData = { 
            기기 : refCategory,
            장소 : fdata.queryData[0].site, 
            부서: fdata.queryData[0].division,
            관리번호 : fdata.queryData[0].managenumber,
            위치 : fdata.queryData[0].location,
            세부위치 : fdata.queryData[0].dlocation,
            Leak여부 : fdata.bodyData[0].list11,
            커넥터상태 : fdata.bodyData[0].list12,
            문짝 : fdata.bodyData[0].list21,
            상태 : fdata.bodyData[0].list22,
            수량 : fdata.bodyData[0].list31,
            상태 : fdata.bodyData[0].list32,
            노즐 : fdata.bodyData[0].list41,
            렌치 : fdata.bodyData[0].list51,
            특이사항 : fdata.bodyData[0].comment,
            점검일 : fdata.createdAt.split("T")[0],
            점검자 : fdata.bodyData[0].writer,

  
          }
          sampleDatas.push(sampleData)
  

        }else if(refCategory=="모니터건"){
          if(refDivision == "MMA2"){
            const sampleData = { 
              기기 : refCategory,
              장소 : fdata.queryData[0].site, 
              부서: fdata.queryData[0].division,
              관리번호 : fdata.queryData[0].managenumber,
              위치 : fdata.queryData[0].location,
              세부위치 : fdata.queryData[0].dlocation,
              Leak여부 : fdata.bodyData[0].list11,
              동작_상하좌우_노즐 : fdata.bodyData[0].list12,
              문짝 : fdata.bodyData[0].list21,
              상태 : fdata.bodyData[0].list22,
              수량 : fdata.bodyData[0].list31,
              상태 : fdata.bodyData[0].list32,
              노즐 : fdata.bodyData[0].list41,
              렌치 : fdata.bodyData[0].list51,
              특이사항 : fdata.bodyData[0].comment,
              점검일 : fdata.createdAt.split("T")[0],
              점검자 : fdata.bodyData[0].writer,
  
    
            }
            sampleDatas.push(sampleData)

          }else{
            const sampleData = { 
              기기 : refCategory,
              장소 : fdata.queryData[0].site, 
              부서: fdata.queryData[0].division,
              관리번호 : fdata.queryData[0].managenumber,
              위치 : fdata.queryData[0].location,
              세부위치 : fdata.queryData[0].dlocation,
              Leak여부 : fdata.bodyData[0].list11,
              커넥터상태 : fdata.bodyData[0].list21,
              V동작_상하 : fdata.bodyData[0].list31,
              V동작_좌우 : fdata.bodyData[0].list41,
              노즐동작 : fdata.bodyData[0].list51,
              표지판상태 : fdata.bodyData[0].list61,
              특이사항 : fdata.bodyData[0].comment,
              점검일 : fdata.createdAt.split("T")[0],
              점검자 : fdata.bodyData[0].writer,
  
    
            }
            sampleDatas.push(sampleData)

          }
          
  

        }else if(refCategory=="폼소화설비"){
          if(refDivision == "MMA2"){
            const sampleData = { 
              기기 : refCategory,
              장소 : fdata.queryData[0].site, 
              부서: fdata.queryData[0].division,
              관리번호 : fdata.queryData[0].managenumber,
              위치 : fdata.queryData[0].location,
              세부위치 : fdata.queryData[0].dlocation,
              Leak여부 : fdata.bodyData[0].list11,
              커넥터상태 : fdata.bodyData[0].list12,
              문짝 : fdata.bodyData[0].list21,
              상태 : fdata.bodyData[0].list22,
              수량 : fdata.bodyData[0].list31,
              상태 : fdata.bodyData[0].list32,
              노즐 : fdata.bodyData[0].list41,
              렌치 : fdata.bodyData[0].list51,
              특이사항 : fdata.bodyData[0].comment,
              점검일 : fdata.createdAt.split("T")[0],
              점검자 : fdata.bodyData[0].writer,
  
    
            }
            sampleDatas.push(sampleData)

          }else{
            const sampleData = { 
              기기 : refCategory,
              장소 : fdata.queryData[0].site, 
              부서: fdata.queryData[0].division,
              관리번호 : fdata.queryData[0].managenumber,
              위치 : fdata.queryData[0].location,
              세부위치 : fdata.queryData[0].dlocation,
              Level : fdata.bodyData[0].list11,
              외관상태 : fdata.bodyData[0].list12,
              LG상태 : fdata.bodyData[0].list13,
              ValveLeak : fdata.bodyData[0].list21,
              노즐 : fdata.bodyData[0].list22,
              호스 : fdata.bodyData[0].list23,
              특이사항 : fdata.bodyData[0].comment,
              점검일 : fdata.createdAt.split("T")[0],
              점검자 : fdata.bodyData[0].writer,
  
    
            }
            sampleDatas.push(sampleData)

          }
          

        }else if(refCategory=="CO2"){
          const sampleData = { 
            기기 : refCategory,
            장소 : fdata.queryData[0].site, 
            부서: fdata.queryData[0].division,
            관리번호 : fdata.queryData[0].managenumber,
            위치 : fdata.queryData[0].location,
            세부위치 : fdata.queryData[0].dlocation,
            용기저장 : fdata.bodyData[0].list11,
            CYL압력 : fdata.bodyData[0].list21,
            CYL충진 : fdata.bodyData[0].list31,
            수동조작상태 : fdata.bodyData[0].list41,
            SIREN수량 : fdata.bodyData[0].list51,
            감지기설치 : fdata.bodyData[0].list61,
            방출표시 : fdata.bodyData[0].list71,
            각배관상태 : fdata.bodyData[0].list81,
            분사노즐막힘 : fdata.bodyData[0].list91,
            안전밸브상태 : fdata.bodyData[0].list101,
            방호구역상태 : fdata.bodyData[0].list111,
            Fan가동상태 : fdata.bodyData[0].list121,
            CO2_설비상태 : fdata.bodyData[0].list131,
            CO2_정리상태 : fdata.bodyData[0].list141,
            특이사항 : fdata.bodyData[0].comment,
            점검일 : fdata.createdAt.split("T")[0],
            점검자 : fdata.bodyData[0].writer,

  
          }
          sampleDatas.push(sampleData)
  

        }else if(refCategory=="스프링클러"){
          const sampleData = { 
            기기 : refCategory,
            장소 : fdata.queryData[0].site, 
            부서: fdata.queryData[0].division,
            관리번호 : fdata.queryData[0].managenumber,
            위치 : fdata.queryData[0].location,
            세부위치 : fdata.queryData[0].dlocation,
            Press1차_Ware : fdata.bodyData[0].list11,
            Press1차_Packing : fdata.bodyData[0].list12,
            Press2차_Ware: fdata.bodyData[0].list21,
            Press2차_Packing : fdata.bodyData[0].list22,
            Drain상태_Ware : fdata.bodyData[0].list31,
            Drain상태_Packing : fdata.bodyData[0].list32,
            Ball상태_Ware : fdata.bodyData[0].list41,
            Ball상태_Packing : fdata.bodyData[0].list42,
            SOV_Ware : fdata.bodyData[0].list51,
            SOV_Packing : fdata.bodyData[0].list52,
            PGBall상태_Ware : fdata.bodyData[0].list61,
            PGBall상태_Packing : fdata.bodyData[0].list62,
            MainV상태_Ware : fdata.bodyData[0].list71,
            MainV상태_Packing : fdata.bodyData[0].list72,
            급수밸브_Ware : fdata.bodyData[0].list81,
            급수밸브_Packing : fdata.bodyData[0].list82,
            Insulation상태_Ware : fdata.bodyData[0].list91,
            Insulation상태_Packing : fdata.bodyData[0].list92,
            Valve및누수상태_Ware : fdata.bodyData[0].list101,
            Valve및누수상태_Packing : fdata.bodyData[0].list102,
            SOV및압력상태_Ware : fdata.bodyData[0].list111,
            SOV및압력상태_Packing : fdata.bodyData[0].list112,
            작동방법표지판상태_Ware : fdata.bodyData[0].list121,
            작동방법표지판상태_Packing : fdata.bodyData[0].list122,
            송수구및_Ware : fdata.bodyData[0].list131,
            송수구및_Packing : fdata.bodyData[0].list132,
            특이사항 : fdata.bodyData[0].comment,
            점검일 : fdata.createdAt.split("T")[0],
            점검자 : fdata.bodyData[0].writer,

  
          }
          sampleDatas.push(sampleData)
  

        }else if(refCategory=="FoamTank"){
          const sampleData = { 
            기기 : refCategory,
            장소 : fdata.queryData[0].site, 
            부서: fdata.queryData[0].division,
            관리번호 : fdata.queryData[0].managenumber,
            위치 : fdata.queryData[0].location,
            세부위치 : fdata.queryData[0].dlocation,
            외관상태 : fdata.bodyData[0].list11,
            Level_mm : fdata.bodyData[0].list21,
            LG상태: fdata.bodyData[0].list31,
            특이사항 : fdata.bodyData[0].comment,
            점검일 : fdata.createdAt.split("T")[0],
            점검자 : fdata.bodyData[0].writer,

  
          }
          sampleDatas.push(sampleData)
  

        }
        
      })



      setXlData(sampleDatas)
      //console.log(xlData)
      //setRefHeader(xlData) 
      

     


        
  
     


    }catch (e) {
        //console.log(e);

      
    }
  }


  const doneWrite = () => {
    //alert("작성되었습니다.")
    
    if(window.confirm("제출하시겠습니까?")){
        
     
             //캔버스 저장 및 평가 결과 
            //save()           

            //fetchData()
            alert("등록되었습니다.")

            setIsOpen(false)
            //fetchGetData()
            
            
            
    
    }else{
        //취소
        return false
    }
   
    
}



  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    //console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    //console.log("close")
}


const View = (data) => {
  
  
  const textHtml= []

  if (data ==null ){
    
    if(refCategory == "소화기"){
      textHtml.push(
        <>
          <tr>
            <td rowSpan={2}>
              NO
            </td>
            <td colSpan={6}>
              소화기
            </td>
            <td colSpan={4}>
              점검내용
            </td>
            <td>
              BOX
            </td>
            <td rowSpan={2}>
              점검일
            </td>
            <td rowSpan={2}>
              점검자
            </td>
          </tr>
          <tr>
            <td>
              Plant
            </td>
            <td>
              부서명
            </td>
            <td>
              관리번호
            </td>
            <td>
              위치
            </td>
            <td>
              세부위치
            </td>
            <td>
              TYPE
            </td>
            <td>
              압력상태
            </td>
            <td>
              노즐상태 
            </td>
            <td>
              안전표지
            </td>
            <td>
              외관상태
            </td>
            <td>
              상태
            </td>
            
  
          </tr>
         
          
        </>
  
      )
      

    }else if(refCategory == "세안기"){
      textHtml.push(
        <>
          <tr>
            <td rowSpan={2}>
              NO
            </td>
            <td colSpan={5}>
              세안기
            </td>
            <td colSpan={6}>
              점검내용
            </td>
            <td rowSpan={2}>
              점검일
            </td>
            <td rowSpan={2}>
              점검자
            </td>
          </tr>
          <tr>
            <td>
              Plant
            </td>
            <td>
              부서명
            </td>
            <td>
              관리번호
            </td>
            <td>
              위치
            </td>
            <td>
              세부위치
            </td>
            
            <td>
              보온상태
            </td>
            <td>
              Leak/부식 
            </td>
            <td>
              E/S작동(Hand V)
            </td>
            <td>
              E/S작동(Foot V)
            </td>
            <td>
              B/S 작동
            </td>
            <td>
              표지판 상태
            </td>
            
  
          </tr>
         
          
        </>
  
      )
      

    }else if(refCategory == "소화전"){
      textHtml.push(
        <>
          <tr>
            <td rowSpan={2}>
              NO
            </td>
            <td colSpan={5}>
              점검표
            </td>
            <td colSpan={2}>
              소화전
            </td>
            <td colSpan={2}>
              Box
            </td>
            <td colSpan={2}>
              소방호스
            </td>
            <td rowSpan={2}>
              노즐
            </td>
            <td rowSpan={2}>
              렌치
            </td>
            <td rowSpan={2}>
              점검일
            </td>
            <td rowSpan={2}>
              점검자
            </td>
          </tr>
          <tr>
            <td>
              Plant
            </td>
            <td>
              부서명
            </td>
            <td>
              관리번호
            </td>
            <td>
              위치
            </td>
            <td>
              세부위치
            </td>
            
            <td>
              Leak여부
            </td>
            <td>
              커넥터 상태 
            </td>
            <td>
              문짝
            </td>
            <td>
              상태
            </td>
            <td>
              수량
            </td>
            <td>
              상태
            </td>
            
  
          </tr>
         
          
        </>
  
      )
      

    }else if(refCategory == "모니터건"){
      if(refDivision == "MMA2"){
        textHtml.push(
          <>
            <tr>
              <td rowSpan={2}>
                NO
              </td>
              <td colSpan={5}>
                점검표
              </td>
              <td colSpan={2}>
                소화전
              </td>
              <td colSpan={2}>
                소화전(Box)
              </td>
              <td colSpan={2}>
                소방호스
              </td>
              <td rowSpan={2}>
                노즐
              </td>
              <td rowSpan={2}>
               렌치
              </td>
              
             
              <td rowSpan={2}>
                점검일
              </td>
              <td rowSpan={2}>
                점검자
              </td>
            </tr>
            <tr>
              <td>
                Plant
              </td>
              <td>
                부서명
              </td>
              <td>
                관리번호
              </td>
              <td>
                위치
              </td>
              <td>
                세부위치
              </td>
              
              <td>
                Leak여부
              </td>
              <td>
                동작<br/>(상하좌우,노즐)
              </td>
              <td>
                둔짝
              </td>
              <td>
                상태
              </td>
              <td>
                수량
              </td>
              <td>
                상태
              </td>
              
    
            </tr>
           
            
          </>
    
        )
      }else{
        textHtml.push(
          <>
            <tr>
              <td rowSpan={2}>
                NO
              </td>
              <td colSpan={5}>
                점검표
              </td>
              <td colSpan={6}>
                모니터건
              </td>
              
             
              <td rowSpan={2}>
                점검일
              </td>
              <td rowSpan={2}>
                점검자
              </td>
            </tr>
            <tr>
              <td>
                Plant
              </td>
              <td>
                부서명
              </td>
              <td>
                관리번호
              </td>
              <td>
                위치
              </td>
              <td>
                세부위치
              </td>
              
              <td>
                Leak여부
              </td>
              <td>
                커넥터 상태 
              </td>
              <td>
                V 동작(상하)
              </td>
              <td>
                V 동작(좌우)
              </td>
              <td>
                노즐동작
              </td>
              <td>
                표지판상태
              </td>
              
    
            </tr>
           
            
          </>
    
        )

      }
      
      

    }else if(refCategory == "폼소화설비"){
      if(refDivision == "MMA2"){
        textHtml.push(
          <>
            <tr>
              <td rowSpan={2}>
                NO
              </td>
              <td colSpan={5}>
                점검표
              </td>
              <td colSpan={2}>
                소화전
              </td>
              <td colSpan={2}>
                소화전(Box)
              </td>
              <td colSpan={2}>
                소방호스
              </td>
              <td rowSpan={2}>
                노즐
              </td>
              <td rowSpan={2}>
               렌치
              </td>
              
             
              <td rowSpan={2}>
                점검일
              </td>
              <td rowSpan={2}>
                점검자
              </td>
            </tr>
            <tr>
              <td>
                Plant
              </td>
              <td>
                부서명
              </td>
              <td>
                관리번호
              </td>
              <td>
                위치
              </td>
              <td>
                세부위치
              </td>
              
              <td>
                Leak여부
              </td>
              <td>
                커넥터상태
              </td>
              <td>
                둔짝
              </td>
              <td>
                상태
              </td>
              <td>
                수량
              </td>
              <td>
                상태
              </td>
              
    
            </tr>
           
            
          </>
    
        )
      }else{

        textHtml.push(
          <>
            <tr>
              <td rowSpan={2}>
                NO
              </td>
              <td colSpan={5}>
                점검표
              </td>
              <td colSpan={3}>
                Foam Tank
              </td>
              <td colSpan={3}>
                기타
              </td>
              
              <td rowSpan={2}>
                점검일
              </td>
              <td rowSpan={2}>
                점검자
              </td>
            </tr>
            <tr>
              <td>
                Plant
              </td>
              <td>
                부서명
              </td>
              <td>
                관리번호
              </td>
              <td>
                위치
              </td>
              <td>
                세부위치
              </td>
              
              <td>
                Level
              </td>
              <td>
                외관 상태
              </td>
              <td>
                LG 상태
              </td>
              <td>
                Valve Leak
              </td>
              <td>
                노즐
              </td>
              <td>
                Hose
              </td>
              
    
            </tr>
           
            
          </>
    
        )
      }
      
      

    }else if(refCategory == "CO2"){
      textHtml.push(
        <>
          <tr>
            <td rowSpan={2}>
              NO
            </td>
            <td colSpan={5}>
              점검표
            </td>
            <td colSpan={16}>
              CO2
            </td>
            
            <td rowSpan={2}>
              점검일
            </td>
            <td rowSpan={2}>
              점검자
            </td>
          </tr>
          <tr>
            <td>
              Plant
            </td>
            <td>
              부서명
            </td>
            <td>
              관리번호
            </td>
            <td>
              위치
            </td>
            <td>
              세부위치
            </td>
            
            <td>
              1
            </td>
            <td>
              2
            </td>
            <td>
              3
            </td>
            <td>
              4
            </td>
            <td>
              5
            </td>
            <td>
              6
            </td>
            <td>
              7
            </td>
            <td>
              8
            </td>
            <td>
              9
            </td>
            <td>
              10
            </td>
            <td>
              11
            </td>
            <td>
              12
            </td>
            <td>
              13
            </td>
            <td>
              14
            </td>
            <td>
              15
            </td>
            <td>
              16
            </td>
            
          </tr>
         
          
        </>
  
      )
      

    }else if(refCategory == "스프링클러"){
      textHtml.push(
        <>
          <tr>
            <td rowSpan={2}>
              NO
            </td>
            <td colSpan={5}>
              점검표
            </td>
            <td colSpan={26}>
              스프링클러
            </td>
            
            <td rowSpan={2}>
              점검일
            </td>
            <td rowSpan={2}>
              점검자
            </td>
          </tr>
          <tr>
            <td>
              Plant
            </td>
            <td>
              부서명
            </td>
            <td>
              관리번호
            </td>
            <td>
              위치
            </td>
            <td>
              세부위치
            </td>
            
            <td colspan="2">
              1
            </td>
            <td colspan="2">
              2
            </td>
            <td colspan="2">
              3
            </td>
            <td colspan="2">
              4
            </td>
            <td colspan="2">
              5
            </td>
            <td colspan="2">
              6
            </td>
            <td colspan="2">
              7
            </td>
            <td colspan="2">
              8
            </td>
            <td colspan="2">
              9
            </td>
            <td colspan="2">
              10
            </td>
            <td colspan="2">
              11
            </td>
            <td colspan="2">
              12
            </td>
            <td colspan="2">
              13
            </td>
            
            
          </tr>
         
          
        </>
  
      )
      

    }else if(refCategory == "FoamTank"){
      textHtml.push(
        <>
          <tr>
            <td rowSpan={2}>
              NO
            </td>
            <td colSpan={5}>
              점검표
            </td>
            <td colSpan={3}>
              Foam Tank
            </td>
            
            <td rowSpan={2}>
              점검일
            </td>
            <td rowSpan={2}>
              점검자
            </td>
          </tr>
          <tr>
            <td>
              Plant
            </td>
            <td>
              부서명
            </td>
            <td>
              관리번호
            </td>
            <td>
              위치
            </td>
            <td>
              세부위치
            </td>
            
            <td>
              외관상태
            </td>
            <td >
              Level(mm)
            </td>
            <td >
              LG 상태
            </td>
            
            
            
          </tr>
         
          
        </>
  
      )
      

    }
    
    return textHtml

  }
}
 

    return(

      
            <Block>
               

              <div  style={{display:'inline-block'}}>
           
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/> 
                  

              </div>
              <div  style={{display:'inline-block'}}>
           
           
              ~

              </div>
             
              <div  style={{display:'inline-block'}}>
           
           
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yy-MM-dd"/>

                 
              

              </div>
              <div  style={{display:'inline-block', marginBottom : '30px'}}>
           
                 
                  <button onClick={() => {dateFilter()}}> 검색</button>
                  


              </div>
           
              

              <div style={{clear:'both'}}>

              </div>
              
              <div>
                
                
                  <p/>
                      <div style={{float:'left'}}>
                            <table className='subTable'>
                              <tr>
                                <th onClick={()=>setWriteOn(!writeOn)}>
                                  {refCategory} 점검표
                                </th>
                                
                              </tr>
                            </table>
                          
                      </div>

                   

                      <div  style={{clear:'both'}}>
                        <hr/>
                      </div>
                      {writeOn && <>
                      <Write match={match} refCategory={refCategory} idInform={null} />
                     </>}
                     
                     
                     {modyOn && <>
                      <button onClick={()=>{setModyOn(false)}}>뒤로가기</button>
                       <Write match={match} refCategory={refCategory} idInform={idInform} />
                       
                             
                     </>}

                     <div className='overScr'>
                      {xlData != null &&
                      
                      <CSVLink data={xlData}  filename={`${refCategory}.csv`}>Excel Down</CSVLink>
                    }
                      
                      
                      <table style={{width:'98%'}}>
                      
                      
                      {!modyOn && <>
                      
                      {View()}
                     
                        {datas !=null && datas.map((data,index)=> <>
                      
                          

                            {refCategory == "소화기" && <>
                            <tr key={index}>
                              <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                  {index}
                              </td>
                              <td>
                                {data.queryData[0].site}
                              </td>
                              <td>
                                {data.queryData[0].division}
                              </td>
                              <td>
                                {data.queryData[0].managenumber}
                              </td>
                              <td>
                                {data.queryData[0].location}
                              </td>
                              <td>
                                {data.queryData[0].dlocation}
                              </td>
                              <td>
                                {data.queryData[0].type}
                              </td>
                              <td>
                                {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                              
                              </td>
                              <td>
                                {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                              
                              </td>
                              <td>
                                {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                              
                              </td>
                              <td>
                                {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                              
                              </td>
                              <td>
                                {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                              
                              </td>
                            
                              <td>
                                {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]} 
                              </td>
                              <td>
                              {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                            </td>
                                
                              
                            </tr>
                                

                            
                            </>}

                            {refCategory == "세안기" && <>
                            <tr key={index}>
                              <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                  {index}
                              </td>
                              <td>
                                {data.queryData[0].site}
                              </td>
                              <td>
                                {data.queryData[0].division}
                              </td>
                              <td>
                                {data.queryData[0].managenumber}
                              </td>
                              <td>
                                {data.queryData[0].location}
                              </td>
                              <td>
                                {data.queryData[0].dlocation}
                              </td>
                              
                              <td>
                                {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list61 != null && <>{data.bodyData[0].list61}</>}
                                
                              </td>
                            
                              <td>
                                {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                              </td>
                              <td>
                              {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                            </td>
                                
                              
                            </tr>
                                

                            
                            </>}
                            {refCategory == "소화전" && <>
                            <tr key={index}>
                              <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                  {index}
                              </td>
                              <td>
                                {data.queryData[0].site}
                              </td>
                              <td>
                                {data.queryData[0].division}
                              </td>
                              <td>
                                {data.queryData[0].managenumber}
                              </td>
                              <td>
                                {data.queryData[0].location}
                              </td>
                              <td>
                                {data.queryData[0].dlocation}
                              </td>
                              
                              <td>
                                {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list12 != null && <>{data.bodyData[0].list12}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list22 != null && <>{data.bodyData[0].list22}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list32 != null && <>{data.bodyData[0].list32}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                                
                              </td>
                              
                            
                              <td>
                                {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                              </td>
                              <td>
                              {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                            </td>
                                
                              
                            </tr>
                                

                            
                            </>}
                            {refCategory == "모니터건" && <>

                              {refDivision == "MMA2" ? <>
                                  <tr key={index}>
                                  <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                      {index}
                                  </td>
                                  <td>
                                    {data.queryData[0].site}
                                  </td>
                                  <td>
                                    {data.queryData[0].division}
                                  </td>
                                  <td>
                                    {data.queryData[0].managenumber}
                                  </td>
                                  <td>
                                    {data.queryData[0].location}
                                  </td>
                                  <td>
                                    {data.queryData[0].dlocation}
                                  </td>
                                  
                                  <td>
                                    {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list12 != null && <>{data.bodyData[0].list12}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list22 != null && <>{data.bodyData[0].list22}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list32 != null && <>{data.bodyData[0].list32}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                                    
                                  </td>
                                  
                                
                                  <td>
                                    {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                                  </td>
                                  <td>
                                  {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                                </td>
                                    
                                  
                                </tr>
                              
                              </> 
                              
                              : <>
                                  <tr key={index}>
                                  <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                      {index}
                                  </td>
                                  <td>
                                    {data.queryData[0].site}
                                  </td>
                                  <td>
                                    {data.queryData[0].division}
                                  </td>
                                  <td>
                                    {data.queryData[0].managenumber}
                                  </td>
                                  <td>
                                    {data.queryData[0].location}
                                  </td>
                                  <td>
                                    {data.queryData[0].dlocation}
                                  </td>
                                  
                                  <td>
                                    {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list61 != null && <>{data.bodyData[0].list61}</>}
                                    
                                  </td>
                                  
                                
                                  <td>
                                    {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                                  </td>
                                  <td>
                                  {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                                </td>
                                    
                                  
                                </tr>
                              </>}
                           
                                

                            
                            </>}
                            {refCategory == "폼소화설비" && <>

                            {refDivision == "MMA2" ? <>
                                  <tr key={index}>
                                  <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                      {index}
                                  </td>
                                  <td>
                                    {data.queryData[0].site}
                                  </td>
                                  <td>
                                    {data.queryData[0].division}
                                  </td>
                                  <td>
                                    {data.queryData[0].managenumber}
                                  </td>
                                  <td>
                                    {data.queryData[0].location}
                                  </td>
                                  <td>
                                    {data.queryData[0].dlocation}
                                  </td>
                                  
                                  <td>
                                    {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list12 != null && <>{data.bodyData[0].list12}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list22 != null && <>{data.bodyData[0].list22}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list32 != null && <>{data.bodyData[0].list32}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                                    
                                  </td>
                                  <td>
                                    {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                                    
                                  </td>
                                  
                                
                                  <td>
                                    {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                                  </td>
                                  <td>
                                  {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                                </td>
                                    
                                  
                                </tr>
                              
                              </> 
                              
                              :<>
                              <tr key={index}>
                              <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                  {index}
                              </td>
                              <td>
                                {data.queryData[0].site}
                              </td>
                              <td>
                                {data.queryData[0].division}
                              </td>
                              <td>
                                {data.queryData[0].managenumber}
                              </td>
                              <td>
                                {data.queryData[0].location}
                              </td>
                              <td>
                                {data.queryData[0].dlocation}
                              </td>
                              
                              <td>
                                {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list12 != null && <>{data.bodyData[0].list12}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list13 != null && <>{data.bodyData[0].list13}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list22 != null && <>{data.bodyData[0].list22}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list23 != null && <>{data.bodyData[0].list23}</>}
                                
                              </td>
                              
                              <td>
                                {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                              </td>
                              <td>
                              {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                            </td>
                                
                              
                            </tr>
                            </>}
                            
                                

                            
                            </>}
                            {refCategory == "CO2" && <>
                            <tr key={index}>
                              <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                  {index}
                              </td>
                              <td>
                                {data.queryData[0].site}
                              </td>
                              <td>
                                {data.queryData[0].division}
                              </td>
                              <td>
                                {data.queryData[0].managenumber}
                              </td>
                              <td>
                                {data.queryData[0].location}
                              </td>
                              <td>
                                {data.queryData[0].dlocation}
                              </td>
                              
                              <td>
                                {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list61 != null && <>{data.bodyData[0].list61}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list71 != null && <>{data.bodyData[0].list71}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list81 != null && <>{data.bodyData[0].list81}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list91 != null && <>{data.bodyData[0].list91}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list101 != null && <>{data.bodyData[0].list101}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list111 != null && <>{data.bodyData[0].list111}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list121 != null && <>{data.bodyData[0].list121}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list131 != null && <>{data.bodyData[0].list131}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list141 != null && <>{data.bodyData[0].list141}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list151 != null && <>{data.bodyData[0].list151}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list161 != null && <>{data.bodyData[0].list161}</>}
                                
                              </td>

                              
                            
                              <td>
                                {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                              </td>
                              <td>
                              {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                            </td>
                                
                              
                            </tr>
                                

                            
                            </>}
                            {refCategory == "스프링클러" && <>
                            <tr key={index}>
                              <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                  {index}
                              </td>
                              <td>
                                {data.queryData[0].site}
                              </td>
                              <td>
                                {data.queryData[0].division}
                              </td>
                              <td>
                                {data.queryData[0].managenumber}
                              </td>
                              <td>
                                {data.queryData[0].location}
                              </td>
                              <td>
                                {data.queryData[0].dlocation}
                              </td>
                              
                              <td>
                                {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list12 != null && <>{data.bodyData[0].list12}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list22 != null && <>{data.bodyData[0].list22}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list32 != null && <>{data.bodyData[0].list32}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list41 != null && <>{data.bodyData[0].list41}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list42 != null && <>{data.bodyData[0].list42}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list51 != null && <>{data.bodyData[0].list51}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list52 != null && <>{data.bodyData[0].list52}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list61 != null && <>{data.bodyData[0].list61}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list62 != null && <>{data.bodyData[0].list62}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list71 != null && <>{data.bodyData[0].list71}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list72 != null && <>{data.bodyData[0].list72}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list81 != null && <>{data.bodyData[0].list81}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list82 != null && <>{data.bodyData[0].list82}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list91 != null && <>{data.bodyData[0].list91}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list92 != null && <>{data.bodyData[0].list92}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list101 != null && <>{data.bodyData[0].list101}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list102 != null && <>{data.bodyData[0].list102}</>}
                                
                              </td>
                              
                              <td>
                                {data.bodyData[0].list111 != null && <>{data.bodyData[0].list111}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list112 != null && <>{data.bodyData[0].list112}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list121 != null && <>{data.bodyData[0].list121}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list122 != null && <>{data.bodyData[0].list122}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list131 != null && <>{data.bodyData[0].list131}</>}
                                
                              </td>
                              <td>
                                {data.bodyData[0].list132 != null && <>{data.bodyData[0].list132}</>}
                                
                              </td>
                              <td>
                                {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                              </td>
                              <td>
                              {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                            </td>
                                
                              
                            </tr>
                                

                            
                            </>}

                            {refCategory == "FoamTank" && <>
                            <tr key={index}>
                              <td onClick={()=>{setModyOn(!modyOn), setIdInform(data._id), setTableIndex(index)}}>
                                  {index}
                              </td>
                              <td>
                                {data.queryData[0].site}
                              </td>
                              <td>
                                {data.queryData[0].division}
                              </td>
                              <td>
                                {data.queryData[0].managenumber}
                              </td>
                              <td>
                                {data.queryData[0].location}
                              </td>
                              <td>
                                {data.queryData[0].dlocation}
                              </td>
                              
                              <td>
                                {data.bodyData[0].list11 != null && <>{data.bodyData[0].list11}</>}
                              
                              </td>
                              <td>
                                {data.bodyData[0].list21 != null && <>{data.bodyData[0].list21}</>}
                              
                              </td>
                              <td>
                                {data.bodyData[0].list31 != null && <>{data.bodyData[0].list31}</>}
                              
                              </td>
                              
                            
                              <td>
                                {data.createdAt.split("T")[0]} <br/> {data.createdAt.split("T")[1].split(".")[0]}
                              </td>
                              <td>
                              {data.bodyData[0].writer != null && <>{data.bodyData[0].writer}</>}
                            </td>
                                
                              
                            </tr>
                                

                            
                            </>}

                          
                          
                          
                        
                       
                        </>)}

                      </>}  
                      
                      


                      </table>
                      </div>

                      

                {filterOn == false && 
                    <Pagination
                    activePage={page}
                    itemsCountPerPage={pagelimit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={"<"}
                    nextPageText={">"}
                    onChange={handlePageChange}
                    />

                 }
              </div>

             

             

               
                
              
         
        
             
                             
              
            </Block>
    
        );
    
   
};

export default List