import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';

import { BarChart, Bar, Brush, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {  LineChart,  Line,  ReferenceLine,} from 'recharts';
import { PieChart, Pie, Sector,  } from 'recharts';
import { AreaChart, Area  } from 'recharts';

import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import { StatusCriticalSmall,CaretRightFill, Analytics,Optimize,Launch, Search, Announce, Technology } from 'grommet-icons';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import Write from './Write';
import ChartDraw from './ChartDraw';



const Block = styled.div`
  overflow:auto;
  
  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

  tr:hover {background-color: lightgrey;}

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      width:'60%',
      height:'60%'

      
  },
  border:{
    border: 'solid'
  }
  

 
  
}



Modal.setAppElement('#root')
const Rotation = ({location}) => {
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)
  const [tagSearchText, setTagSearchText] = useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    //console.log(location)
    const [category, setCategory] = useState('rotation')
    const [writeOn,setWriteOn] = useState(false)
    const [modyOn,setModyOn] = useState(false)
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [ selectType, setSelectType ] = useState('all')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)

    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      //if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    //console.log(startDate)
    //console.log("날짜 정렬")
    //console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  const tagFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      
      if(refData.tag.match(new RegExp(tagSearchText,"i"))){
        return true;
      }
    })
    
    setDatas(filterData)
    setFilterOn(true);
  })

  const dataSort = useCallback(()=>{
    //내림차순 정렬 
    if(filterOn){
      const sorted_list = datas.sort((a,b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      //console.log('filter On')
      setDatas(sorted_list)
      setTotalCount(sorted_list.length)

    }else{
      const sorted_list = refDatas.sort((a,b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      //console.log('filter off')
      setDatas(sorted_list.slice((page-1)*pagelimit,(page*pagelimit)))
      setTotalCount(sorted_list.length)
    }
  })

  const itemSort = useCallback((tagItem) =>{
    //console.log(tagItem)
    if (filterOn){
      const filterData = datas.filter(refData => {
      
        if(refData.tag == tagItem){
          return true
        }
      })
  
      //console.log(filterData)
      setDatas(filterData)
      setFilterOn(true);

    }else{
      const filterData = refDatas.filter(refData => {
      
        if(refData.tag == tagItem){
          return true
        }
      })
  
      //console.log(filterData)
      setDatas(filterData)
      setFilterOn(true);

    }
    

  })

  

    const [ tableIndex, setTableIndex ] = useState(null)


    const [form, setForm] = useState ({})

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {
    //console.log(cookies.auth)
    fetchGetData();
    //fetchLoginCheck();
    //fetchData();

  },[])

  const onTypeChange = e => {
    
    if (e.target.value == "all"){
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))

      setFilterOn(false)
    
      setTotalCount(refDatas.data.length)
        
    }else{

      setSelectType(e.target.value)

      

      if (filterOn){
        const filterData = datas.filter(refData => {
        
          if(refData.rotationSeparation == e.target.value){
            return true
          }
        })
    
        //console.log(filterData)
        setDatas(filterData)
        setFilterOn(true);
  
      }else{
        const filterData = refDatas.filter(refData => {
        
          if(refData.rotationSeparation == e.target.value){
            return true
          }
        })
    
        //console.log(filterData)
        setDatas(filterData)
        setFilterOn(true);
  
      }
    
    
    }
    
    //setTotalCount(filterData.length)
    //setFilterOn(true);
  }



  const fetchGetData = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_SOCKET}/allplantdata?type=rotation&location=${location}`,{
                headers:{
                    token : cookie.auth
                }
            }
        );

        setDatas(response.data.slice((page-1)*pagelimit,(page*pagelimit)))
        setRefDatas(response.data)
        setFilterOn(false)
      
        setTotalCount(response.data.length)

        //console.log(response.data)

       


    }catch (e) {
        //console.log(e);

       
    }
  }



  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    //console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    //console.log("close")
}

const chartData = (inChargePerson) =>{
  const xLabel = [];
  const yData = [];
  
  
    for (let i = 0; i < datas.length; i++) {
      if(datas[i].rotationVib != 'undefined' ){
        xLabel.push(datas[i].createdAt.split('T')[0])

        if(inChargePerson == "진동측정"){
          yData.push(datas[i].rotationVib)
        }else if(inChargePerson == "온도측정"){
          yData.push(datas[i].rotationTemp)
        }
        
      }
     
        //yDatas.push(datas[i].rotationVib)
    }

  
  
  return [xLabel, yData]
}

 

    return(
            <Block>
              
            {!writeOn && <>
              {!modyOn && 
                <button onClick={()=>setWriteOn(true)}>입력하기</button>
              }        
            </>} 
            {writeOn && 
              <button onClick={()=>setWriteOn(false)}>뒤로가기</button>
            }

            {writeOn && <Write location={location} refCategory={category} />}

            {modyOn ? <>
            <button onClick={()=>setModyOn(false)}>뒤로가기</button>
            <p/>
            <Write location={location} id={idInform} refCategory={category} /> </> : <>

              
              
              
              <div>
                
                
                <table>
                <p/>
                      <div style={{float:'left'}}>
                            <table className='subTable'>
                              <tr>
                                <th rowSpan={8}>
                                  측정항목
                                </th>
                                <th>
                                  설비중요등급:A(A1)
                                </th>
                                <th>
                                  설비중요등급:B(A2)
                                </th>
                                <th>
                                  설비중요등급:B(B1)
                                </th>
                                <th>
                                  설비중요등급:C(B2)
                                </th>
                                <th>
                                  설비중요등급:D(C1)
                                </th>
                                
                              </tr>
                              <tr>
                                <th>
                                  진동측정
                                </th>
                                <th>
                                  진동측정
                                </th>
                                <th>
                                  X
                                </th>
                                <th>
                                  X
                                </th>
                                <th>
                                  X
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  OIL점검 (급유)
                                </th>
                                <th>
                                  OIL점검 (급유)
                                </th>
                                <th>
                                  X
                                </th>
                                <th>
                                  OIL점검 (급유)
                                </th>
                                <th>
                                  OIL점검 (급유)
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  Bearing 온도 측정
                                </th>
                                <th>
                                  Bearing 온도 측정
                                </th>
                                <th>
                                  X
                                </th>
                                <th>
                                  X
                                </th>
                                <th>
                                  X
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  X
                                </th>
                                <th>
                                  X
                                </th>
                                <th>
                                  MOTOR LAMP점검
                                </th>
                                <th>
                                  X
                                </th>
                                <th>
                                  X
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  음향(이음)
                                </th>
                                <th>
                                  음향(이음)
                                </th>
                                <th>
                                  음향(이음)
                                </th>
                                <th>
                                  음향(이음)
                                </th>
                                <th>
                                  음향(이음)
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  육안점검(부식,파손,LEAK등)
                                </th>
                                <th>
                                육안점검(부식,파손,LEAK등)
                                </th>
                                <th>
                                육안점검(부식,파손,LEAK등)
                                </th>
                                <th>
                                육안점검(부식,파손,LEAK등)
                                </th>
                                <th>
                                육안점검(부식,파손,LEAK등)
                                </th>
                              </tr>
                              <tr>
                                <th>
                                  중요Comp 및 Fan, KCP
                                </th>
                                <th>
                                  CENTRIFUGAL PUMP
                                </th>
                                <th>
                                  CANNED PUMP
                                </th>
                                <th>
                                  RECIPORO DIAPH PUMP
                                </th>
                                <th>
                                  AGITATOR
                                </th>
                              </tr>
                              
                            </table>
                          
                      </div>

                      <div  style={{float:'right'}}>
                          <table className='subTable'>
                            <tr>
                              <th>
                                점검일
                              </th>
                              <th >
                                점검자
                              </th>
                              <th>
                                확인자
                              </th>

                            </tr>
                            <tr>
                              <th  width='80px' height='80px'>
                                
                              
                              </th>
                              <th  width='80px' height='80px'>
                              
                              </th>
                              <th width='80px' height='80px'>
                              
                              </th>
                            </tr>
                            
                          </table>
                          
                      </div>


                      


                     
             

          <div  style={{clear:'both'}}>
            <hr/>
          </div>
          <div  style={{display:'inline-block'}}>
           
           
           <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/> 


             </div>
             <div  style={{display:'inline-block'}}>
           
           
             ~

             </div>
             
             <div  style={{display:'inline-block'}}>
           
           
                 <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yy-MM-dd"/>

                 
             

             </div>
             <div  style={{display:'inline-block', marginBottom : '30px'}}>
           
                 
                 <button onClick={() => {dateFilter()}}> 검색</button>
                 


             </div>
             
             &emsp;&emsp;&emsp;Tag : <input type='text' name='tagSearchText' onChange={(e)=>setTagSearchText(e.target.value)} /><button onClick={() => {tagFilter()}}>검색</button>

            
              <br/>

                      Type :
                      <select onChange={onTypeChange}>
                        <option value="all">All</option>
                        <option value="a1">A1</option>
                        <option value="a21">A2-1</option>
                        <option value="a22">A2-2</option>
                        <option value="a23">A2-3</option>
                        <option value="a24">A2-4</option>
                        <option value="a25">A2-5</option>
                        <option value="b11">B1-1</option>
                        <option value="b12">B1-2</option>
                        <option value="b13">B1-3</option>
                        <option value="b2">B2</option>
                        <option value="c1">C1</option>
                
                    </select>
                    &nbsp;<button onClick={()=>{fetchGetData()}}>새로고침</button>&nbsp;
                    <button onClick={()=>window.print()}>인쇄하기</button>

                    <p/>

          
                    
                    
                      {selectType == "b2" ?  <>

                        <table style={{width:'98%'}}>
                          <tr >
                          <th rowSpan={2}>
                              No
                            </th>
                          <th rowSpan={2} onClick={()=>dataSort()}>
                              점검일
                            </th>
                            <th rowSpan={2}>
                              Item No.
                            </th>
                            <th rowSpan={2}>
                              Grade
                            </th>
                            <th rowSpan={2}>
                              SERVICE
                            </th>
                            <th rowSpan={2}>
                              TYPE
                            </th>
                            <th colSpan={4}>
                              점검항목
                            </th>
                            
                            <th rowSpan={2}>
                              Remarks
                            </th>

                          </tr>

                          <tr >
                          
                          
                            <th>
                              OIL<br/>LEVEL점검
                            </th>
                            <th>
                              OIL<br/>급유 
                            </th>
                           
                            <th>
                              음향(이음)
                            </th>
                            <th>
                              육안점검<br/>(부식,파손,Leak등)
                            </th>
                            

                          </tr>
                        {datas !=null && datas.map((data,index)=> <>
                          <tr key={index}>
                            <td onClick={()=>{setModyOn(true), setIdInform(data._id)}}>
                                {index}
                              </td>
                            <td>
                              {data.createdAt.split("T")[0]}
                              </td>
                              
                              <td onClick={() => {itemSort(data.tag)}}>
                              {data.tag != 'undefined' ? data.tag : '-'} 
                              </td>
                              <td>
                                {data.rotationSeparation != 'undefined' ? data.rotationSeparation.toUpperCase() : '-'} 
                              </td>
                              <td>
                              {data.rotationService != 'undefined' ? data.rotationService : '-'}
                              </td>
                              <td>
                              {data.rotationType != 'undefined' ? data.rotationType : '-'}
                              </td>
                              
                              <td>
                              {data.rotationOilLevel != 'undefined' ? data.rotationOilLevel : '-'}
                              </td>
                              <td>
                              {data.rotationOilMake != 'undefined' ? data.rotationOilMake : '-'}
                              </td>
                              <td>
                              {data.rotationSound != 'undefined' ? data.rotationSound : '-'}
                              </td>
                              <td>
                              {data.rotationExternal != 'undefined' ? data.rotationExternal : '-'}
                              </td>
                              
                              
                              <td>
                                {data.remarks != 'undefined' ? data.remarks : '-'}
                              </td>
                          </tr>

                        </>)}



                        </table>



                        </> : <>
                              {selectType.includes("b") ? <>
                            <table style={{width:'98%'}}>
                              <tr >
                              <th rowSpan={2}>
                                  No
                                </th>
                              <th rowSpan={2} onClick={()=>dataSort()}>
                                  점검일
                                </th>
                                <th rowSpan={2}>
                                  등급
                                </th>
                                <th rowSpan={2}>
                                  Item No.
                                </th>
                                <th rowSpan={2}>
                                  SERVICE
                                </th>
                                <th rowSpan={2}>
                                  TYPE
                                </th>
                                <th colSpan={2}>
                                  LAP점등상태
                                </th>
                                <th colSpan={1}>
                                  음향(이음)
                                </th>
                                <th colSpan={1}>
                                  육안점검<br/>
                                  부식, 파손, Leak등
                                </th>
                                

                                <th rowSpan={2}>
                                  Remarks
                                </th>

                              </tr>

                              <tr >
                              
                                <th>
                                  AXIAL
                                </th>
                                <th>
                                  RADIAL
                                </th>
                                <th>
                                  양호/불량
                                </th>
                                <th >
                                  양호/불량
                                </th>
                                
                                

                              </tr>
                            {datas !=null && datas.map((data,index)=> <>
                              <tr key={index}>
                                <td onClick={()=>{setModyOn(true), setIdInform(data._id)}}>
                                    {index}
                                  </td>
                                <td>
                                  {data.createdAt.split("T")[0]}
                                  </td>
                                  <td>
                                  {data.rotationSeparation != 'undefined' ? data.rotationSeparation.toUpperCase() : '-'} 
                                  </td>
                                  
                                  <td onClick={() => {itemSort(data.tag)}}>
                                  {data.tag != 'undefined' ? data.tag : '-'} 
                                  </td>
                                  
                                  <td>
                                  {data.rotationService != 'undefined' ? data.rotationService : '-'}
                                  </td>
                                  <td>
                                  {data.rotationType != 'undefined' ? data.rotationType : '-'}
                                  </td>
                                  <td>
                                  {data.rotationLampAxial != 'undefined' ? data.rotationLampAxial : '-'}
                                  </td>
                                  <td>
                                  {data.rotationLampRadial != 'undefined' ? data.rotationLampRadial : '-'}
                                  </td>
                                  <td>
                                  {data.rotationExternal != 'undefined' ? data.rotationExternal : '-'}
                                  </td>
                                  <td>
                                  {data.rotationTemp != 'undefined' ? data.rotationTemp : '-'}
                                  </td>
                                  
                                  
                                  
                                  <td>
                                    {data.remarks != 'undefined' ? data.remarks : '-'}
                                  </td>
                              </tr>

                            </>)}
                            


                            </table>
                            </> 
                            : <>

                              <table style={{width:'98%'}}>
                                <tr >
                                <th rowSpan={2}>
                                    No
                                  </th>
                                <th rowSpan={2} onClick={()=>dataSort()}>
                                    점검일
                                  </th>
                                  <th rowSpan={2}>
                                    Item No.
                                  </th>
                                  <th rowSpan={2}>
                                    Grade
                                  </th>
                                  <th rowSpan={2}>
                                    SERVICE
                                  </th>
                                  <th rowSpan={2}>
                                    TYPE
                                  </th>
                                  <th colSpan={6}>
                                    점검항목
                                  </th>
                                  
                                  <th rowSpan={2}>
                                    Remarks
                                  </th>

                                </tr>

                                <tr >
                                
                                  <th onClick={()=>{openModal("진동측정")}}>
                                    진동 측정값<br/>(mm/s)
                                  </th>
                                  <th>
                                    OIL<br/>LEVEL점검
                                  </th>
                                  <th>
                                    OIL<br/>급유 
                                  </th>
                                  <th onClick={()=>{openModal("온도측정")}}>
                                    온도측정<br/>Bearing(C)
                                  </th>
                                  <th>
                                    음향(이음)
                                  </th>
                                  <th>
                                    육안점검<br/>(부식,파손,Leak등)
                                  </th>
                                  

                                </tr>
                              {datas !=null && datas.map((data,index)=> <>
                                <tr key={index}>
                                  <td onClick={()=>{setModyOn(true), setIdInform(data._id)}}>
                                      {index}
                                    </td>
                                  <td>
                                    {data.createdAt.split("T")[0]}
                                    </td>
                                    
                                    <td onClick={() => {itemSort(data.tag)}}>
                                    {data.tag != 'undefined' ? data.tag : '-'} 
                                    </td>
                                    <td>
                                      {data.rotationSeparation != 'undefined' ? data.rotationSeparation.toUpperCase() : '-'} 
                                    </td>
                                    <td>
                                    {data.rotationService != 'undefined' ? data.rotationService : '-'}
                                    </td>
                                    <td>
                                    {data.rotationType != 'undefined' ? data.rotationType : '-'}
                                    </td>
                                    <td>
                                    {data.rotationVib != 'undefined' ? data.rotationVib : '-'}
                                    </td>
                                    <td>
                                    {data.rotationOilLevel != 'undefined' ? data.rotationOilLevel : '-'}
                                    </td>
                                    <td>
                                    {data.rotationOilMake != 'undefined' ? data.rotationOilMake : '-'}
                                    </td>
                                    <td>
                                    {data.rotationTemp != 'undefined' ? data.rotationTemp : '-'}
                                    </td>
                                    <td>
                                    {data.rotationSound != 'undefined' ? data.rotationSound : '-'}
                                    </td>
                                    <td>
                                    {data.rotationExternal != 'undefined' ? data.rotationExternal : '-'}
                                    </td>
                                    
                                    
                                    <td>
                                      {data.remarks != 'undefined' ? data.remarks : '-'}
                                    </td>
                                </tr>

                              </>)}
                            


                            </table>
                            
                            
                            
                            </>}
                        
                        
                        
                        </>}

                      

                      
                      
                
                  
                 
                </table>

                {filterOn == false && 
                    <Pagination
                    activePage={page}
                    itemsCountPerPage={pagelimit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={"<"}
                    nextPageText={">"}
                    onChange={handlePageChange}
                    />

                 }
              </div>

             

              <div style={{clear:'both', margin:'20px'}}>

              </div>

              </>}

                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen = {afterOpenModal}
                    onRequestClose={closeModal}
                    
                    style = {customStyles}
                    contentLabel = "write"
                    >
                    
                    
                <div >
                  
                    {datas != null && <ChartDraw chartData = {chartData(inChargePerson)} name={inChargePerson}  /> }
                    
                    

                </div>

                                            
                                             
                </Modal>

               
                
              
         
        
             
                             
              
            </Block>
              
        );
    
   
};

export default Rotation