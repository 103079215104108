import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect } from 'react';

import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';



const { Header, Footer, Sider, Content } = Layout;
import { Button, Checkbox, Form, Input } from 'antd';

import { Breadcrumb, Layout, Menu, theme } from 'antd';


import Electric from './Electric.js'


import Rotation from './Rotation.js'
import SubStation from './Substation.js'
import Generator from './Generator.js'
import Loadcell from './Loadcell.js'
import BatteryCheck from './BatteryCheck.js';
import HeaterCheck from './HeaterCheck.js';
import DistPanelCheck from './DistPanelCheck.js';
import GroundingCheck from './GroundingCheck.js';
import Hoist from './Hoist.js';


import Write from './Write.js'
import {Mobile, PC} from '../resView.js'



const LoginContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
`;

const LoginForm = styled(Form)`
  width: 100%;
  max-width: 400px;
  padding: 24px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;



const Block = styled.div`


  font-size: 1rem;
  

  table {
  width: 95%;
  margin: 20px;
  border-collapse: collapse;
  
  
  
  
  
  }

  th, td {
    border-bottom: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

  

  Input{
    max-width: 100px;
    
    text-align: center;
  }





`


const index = ({match}) => {

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth', 'category']);


  const [receivedAuthNumber, setReceivedAuthNumber] = useState(null);
  const [ sendOk, setSendOk] = useState(false)

    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [login, setLogin] = useState(false)
    const [form, setForm] = useState ({})


    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  

  useEffect(() => {
    //console.log(cookies.auth)
    
    //fetchData();
    fetchLoginCheck();

    
    

  },[cookie.category])


  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            
            setLogin(false)
        }else if (response.data.code == 200){
          //history.push(`/pm/${match.params.location}`)
          setLogin(true)
        }

        //


    }catch (e) {
        //console.log(e);
        setLogin(false)
        
       
    }
  }


  const loginBtn = async() => {
    //console.log("제출 클릭 ")
    //console.log(form.id)
    //console.log(form.pwd)
    
    
      
      
  
  
    
};
    
  const keys = ["elec", "rotation", "substation", "generator", "loadcell", "battery", "heater", "dist", "grounding", "hoist"]; // 원하는 항목을 추가
  const items = ["전기계장", "회전기기", "SubStation", "비상발전기", "LoadCell", "Battery", "Heater", "Dist Panel", "Grounding", "Hoist"]; // 원하는 항목을 추가

  const formattedItems = items.map((item, index) => ({
    key: keys[index],   // 각 항목의 고유 키
    label: item       // 배열의 각 값이 label로 설정
  }));

  const handleMenuClick = (e) => {
    const selectedItem = formattedItems.find((item) => item.key === e.key)?.key;
    setCategory(selectedItem || ''); // 선택된 항목 업데이트
  };
  

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onFinish = async(values) => {
    //console.log(values["userId"])
    try {
      //console.log("값확인 ")

      const response = await axios.post(
        `${process.env.REACT_APP_HOST_RPA}/rpa/account/token?id=${values["userId"]}&password=${values["userPw"]}`

      );

      //console.log(response.data.code)                
      //console.log(response.data)                
      if (response.data.code == 400) {
        alert("로그인정보가 존재하지 않아요")
        setLogin(false)
      } else if (response.data.code == 200) {
        //1시간만 유지
        setCookie('auth', response.data.token, { maxAge: 1800 });
        setCookie('name', response.data.name, { maxAge: 1800 });
        setLogin(true)
        setSendOk(false)


        //history.push("/sales");
      }





    } catch (e) {
      //console.log(e);

    }

  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

 

    return(
            <>
        
                {login ?  <Block>

                    <Layout>
                      <Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div className="demo-logo" />
                        <Menu
                          theme="dark"
                          mode="horizontal"
                          defaultSelectedKeys={['elec']}
                          items={formattedItems}
                          onClick={handleMenuClick}
                          style={{
                            flex: 1,
                            minWidth: 0,
                          }}
                        />
                      </Header>
                      <Content
                        style={{
                          padding: '0 48px',
                        }}
                      >
                        <Breadcrumb
                          style={{
                            margin: '16px 0',
                          }}
                        >
                          <Breadcrumb.Item>Home</Breadcrumb.Item>
                          <Breadcrumb.Item>List</Breadcrumb.Item>
                          <Breadcrumb.Item>{category}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div
                          style={{
                            background: colorBgContainer,
                            minHeight: 280,
                            padding: 24,
                            borderRadius: borderRadiusLG,
                          }}
                        >
                              {category == "elec" && <>


                                <Electric location={match.params.location} />


                              </>
                              }

                              {category == "rotation" && <>


                                <Rotation location={match.params.location} />


                              </>
                              }

                              {category == "write" &&

                                <Write location={match.params.location} refCategory={'elec'} />
                              }

                              {category == "substation" &&

                                <SubStation location={match.params.location} refCategory={'substation'} />
                              }

                              {category == "generator" &&

                                <Generator location={match.params.location} refCategory={'generator'} />
                              }

                              {category == "loadcell" &&

                                <Loadcell location={match.params.location} refCategory={'loadcell'} />
                              }

                              {category == "battery" &&

                                <BatteryCheck location={match.params.location} refCategory={'battery'} />
                              }

                              {category == "heater" &&

                                <HeaterCheck location={match.params.location} refCategory={'heater'} />
                              }

                              {category == "dist" &&

                                <DistPanelCheck location={match.params.location} refCategory={'dist'} />
                              }

                              {category == "grounding" &&

                                <GroundingCheck location={match.params.location} refCategory={'grounding'} />
                              }

                              {category == "hoist" &&

                                <Hoist location={match.params.location} refCategory={'hoist'} />
                              } 
                        </div>
                      </Content>
                      <Footer
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        대산공무담당
                      </Footer>
                    </Layout>


                    
                                    
                
                </Block> : <>

              <LoginContainer>
                <LoginForm
                  name="login"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Username"
                    name="userId"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="userPw"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      Login
                    </Button>
                  </Form.Item>
                </LoginForm>
              </LoginContainer>
                  
                
                </>} 
                
                
               
                
              </>

    
        );
    
   
};

export default index