import React from 'react';
import { useState, useCallback,useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {useCookies} from 'react-cookie';

const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
     
    }

    .customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px ;
    }

  

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        height : 300px;
    }

    .customers input {
        width: 89%;
    }

    .customers select{
        width : 90%;
    }
    
        
  

    .btn{
        width:100% ;
        height: 50px;
        background-color: lightgray;
        color: black ;
        font-size: 18px;
        
        
    }

   
`

const index = ({site}) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);
    
    const [dataList, setDataList] = useState([]);
    const [transportVendorList, setTransportVendorList] = useState([]);
    const[refDatas, setRefDatas] = useState(null)

    
    //const size = match.params.location
   
    //const location = data.location
    //console.log(site)

    const [loading, setLoading] = useState(false)
    const [imgUpload, setImgUpload] = useState(false)
    const [form, setForm] = useState ({
        
        orderNumber : '', //주문 번호 
        refTransVendor : ''



        
    })
    const [startDate, setStartDate] = useState(new Date());
    const [orderDateText, setOrderDateText] = useState(new Date());

    useEffect(() => {
              
     
        fetchGetData();
        

    },[cookie.auth])

    const fetchGetData = async() => {
    
        try{
                    

            const response = await axios.get(
                `${process.env.REACT_APP_HOST_RPA}/rpa/transportvendorlist`,{
                    
                    headers:{
                        'token': cookie.auth,
                        
                    }
                }
            );
            

            //console.log("새로고침 ")


            
            if(response.data.articles.length != 0){
             
                setTransportVendorList(response.data.articles)
                //setRefDatas(response.data.articles)

                //console.log(response.data.articles)
             

                
            }else{
                setTransportVendorList(null)
            }
            
            
            
    
        }catch (e) {
            //console.log(e);
           
        }
        
    
      };

    const onChange = useCallback(e => {
        
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
    },[form]);

    const GetVendorList = useCallback((e) => {
        //console.log(e.target.value)

        const nextForm={
            ...form,
            [e.target.name]:e.target.value
        }

        setForm(nextForm)

        
        if(e.target.value.length <=1) return;

        
        
    // 필터링
        const filterData = transportVendorList.filter(refData=> { 
            
            if ( refData.vendorname.includes(e.target.value) ) {
                    
                return true;
            } 
            })

            //console.log(filterData)
            setRefDatas(filterData)
    
        
        
    
    },);

    const addDataList = () => {

        if( form.orderNumber== ''  || form.refTransVendor == '' ){
            alert('빈 값이 존재합니다.')
            return;
        }

        if(form.orderNumber.replace(/ /g,"").length != 7){
            alert("주문번호가 올바르지 않습니다.")
            return;
        }

        
        const nextList = [
            ...dataList, form
        ]
          
          setDataList(nextList)

          //console.log(dataList)

    }

    const deleteDataList = (index) =>{
        
        dataList.splice(index,1)

        const nextList = [
            ...dataList
        ]
        
        setDataList(nextList)

        

    }


    const submitData = async() => {
    
        if(dataList.length == 0 ){
            alert("List Null")
            return
        }

    


    if(window.confirm("등록할까요?")){
        setLoading(true)
    

            //console.log(new Date(orderDateText).toISOString().split("T")[0].replace(/-/g,"/"))
            
        try{
            const title = "sms발송";
            let rpadata =''

            for(var i = 0; i < dataList.length; i++){ 
                
                rpadata = rpadata + "sms " +  dataList[i].refTransVendor.trim() + " " +
                    dataList[i].orderNumber.replace(/ /g,"").trim() + " "


                
            }

            //console.log(rpadata)
            
            

            const enrollData = await axios.post(
            `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                
            },{
                headers:{
                    'token': cookie.auth
                }
                        
                }
            );

    
                //console.log(enrollData.data)
                
                setLoading(false)
                alert("등록되었습니다.")
                
                window.location.reload();
                
            

        }catch{
            setLoading(false)
            //console.log("등록 실패" )
            alert("등록이 실패하였습니다.")

        }
    
    }

    
    
    }
        

    return(
            <>
              
                 <Block>
                 
                 <table className='customers'>
                    
                    <tr>
                            <td colSpan='2' style={{textAlign:'center'}}>
                                SMS 발송  
                             
                            </td>
                            
                           
                        </tr>
                     
                        <tr>
                            <td>
                                운송사 
                            </td>
                            <td>
                                <select name="refTransVendor" onChange={onChange}>
                                    <option value="">운송사를 선택해주세요</option>
                                    <option value="세창종합운수">세창종합운수</option>
                                    <option value="서평">서평</option>
                                    <option value="태양">태양</option>
                                </select>

                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                주문번호 
                            </td>
                            <td>
                                <input type='text' name='orderNumber' onChange={onChange}  />
                            </td>
                        </tr>
                    
                    
                 </table>

                 <button className='btn' onClick={()=>addDataList()} >+ 추가 </button>
                <table className='customers'>
                    <tr>  
                        <td>
                            - List -
                       
                        </td>
                           
                          
                    </tr>
                 {dataList.length !=0  && <>
                        {dataList.map((data,index) => (<>
                    
                    <tr key={index}> 
                        <td>
                            [ {index} ] - <button onClick={()=> deleteDataList(index)} >삭제</button>
                            <br/>
                            운송사 : {data.refTransVendor} || 주문번호 : {data.orderNumber}
                            
                        </td>
                        
                        
                    </tr>
                      </>))}
                    </>
                    }
                </table>

                 <button className='btn' onClick={()=>submitData()} >SMS 발송</button>

                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index