import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {useCookies} from 'react-cookie';


const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }

    .customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px ;
    }

  

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        height : 300px;
    }

    .customers input {
        width: 90%;
    }
        
  

    .btn{
        width:100% ;
        height: 50px;
        background-color: lightgray;
        color: black ;
        font-size: 18px;
        
        
    }
`

const index = ({data}) => {
    
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);

    //const size = match.params.location
   
    //const location = data.location
    //console.log(site)

    const [loading, setLoading] = useState(false)
    const [imgUpload, setImgUpload] = useState(false)
    const [beforeImg, setBeforeImg] = useState('')
    const [afterImg, setAfterImg] = useState('')
    const [polymer, setPolymer] = useState(true)
    const [form, setForm] = useState ({
       orderNumberText: '',
       transportVendorText:'',
       transportNumberText:'',
       orderAmount:'0',

        
    })

    const [carlist, setCarlist] = useState([]);
  
    const [orderDateText, setOrderDateText] = useState(new Date());
    const [orderArriveDateText, setOrderArriveDateText] = useState(new Date());
    

    const onChange = useCallback(e => {
    
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       //console.log(e.target.value)
        
    },[form]);

    useEffect(() => {

        if(data != null){
           form.orderNumberText = data.sapordernumber
              
        //console.log("nnull아님 ")
              
        }else{
            form.orderNumberText = ''
              
            //console.log("nnull아님 ")
        }
       


       
        //print(nextForm)
        //GetCarList()
    
    },[]);

    const today =(value) =>{
        
        const year = value.getFullYear();
        const month = ('0' + (value.getMonth()+1)).slice(-2);
        const day = ('0' + value.getDate()).slice(-2);

        const dateString = year + '/' + month + '/' + day;

        return dateString
    }

    const GetCarList = async(e) => {
        //console.log(e.target.value)
        const nextForm={
            ...form,
            [e.target.name]:e.target.value
        }

        setForm(nextForm)

        if(Number(e.target.value.length <=2)) return;
    
        try{
            if(cookie.name.split(" ")[0] == "B0001111"){

            //console.log(form.transportVendorText)
            const response = await axios.get(
                `${process.env.REACT_APP_HOST_RPA}/rpa/carlistshort?vendorname=${form.transportVendorText.split("_")[1]}&carnumber2=${e.target.value}`,{
                    
                    headers:{
                        'token': cookie.auth
                    }
                }
            );
            
            //console.log(response.data.articles)
            //console.log(response.data.articles.length)
            
                if(response.data.articles.length != 0){
                
                setCarlist(response.data.articles)
                    
                }else{
                    setCarlist(null)
                }


            }else{

                //console.log('car start')
            const response = await axios.get(
                `${process.env.REACT_APP_HOST_RPA}/rpa/carlistshort?vendorname=${cookie.name.replace(/'/g,"").split(" ")[1]}&carnumber2=${e.target.value}`,{
                    
                    headers:{
                        'token': cookie.auth
                    }
                }
            );
            
            //console.log(response.data.articles)
            //console.log(response.data.articles.length)
            
            if(response.data.articles.length != 0){
             
               setCarlist(response.data.articles)
                
            }else{
                setCarlist(null)
            }

            }
            
            
            
            
    
        }catch (e) {
            //console.log(e);
           
        }
        
    
      };


      const submitData = async() => {
        

        //alert(form.orderNumberText)
        
        if(form.transportNumberText == '' || form.transportNumberText.split(" ")[2] == "None"){
            alert('차량번호가 적합하지 않습니다.')
            return;
        }

        
      

        if(window.confirm("등록할까요?")){
            setLoading(true)
        

            try{

                /*
                post
                url/rpa?title=&rpadata
                 title = "배차처리_기주문 " + orderNumberTextView.getText().toString().trim();
            rpadata = orderNumberTextView.getText().toString().trim() + " " + transportVendorText + " " + transportNumberText + " "
                    + orderDateTextView.getText().toString() + " "
                    + orderArriveDateTextView.getText().toString();

                */
                
                if(cookie.name.split(" ")[0] == "B0001111"){
                    
                    let title = ''
                    
                    if (polymer){
                        title = "배차처리_기주문건 " + form.orderNumberText.trim();
                        //console.log("polyemr")

                    }else{ 
                        //console.log("monomer")
                        title = "배차확정 " + form.orderNumberText.trim();
                    }

                    //console.log(title)
                    const rpadata = form.orderNumberText.trim() + " " 
                    + form.transportVendorText.split("_")[0] + " " + form.transportVendorText.split("_")[1] + " " + form.transportNumberText + " "
                    + today(orderDateText) + " "
                    + today(orderArriveDateText) + " "
                    + today(orderDateText) + " " 
                    + form.orderAmount + " ";

                    
                    //console.log(rpadata)
                    const enrollData = await axios.post(
                        `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                            
                           
                    },{
                        headers:{
                            'token': cookie.auth
                        }
                               
                        }
                     );
                    


                }else{
                    const title = "배차처리_기주문건 " + form.orderNumberText.trim();
                    const rpadata = form.orderNumberText.trim() + " " 
                    + cookie.name.replace(/'/g,"") + " " + form.transportNumberText + " "
                    + today(orderDateText) + " "
                    + today(orderArriveDateText) + " " 
                    + today(orderDateText) + " " 
                    + form.orderAmount + " ";;

               

                    const enrollData = await axios.post(
                        `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                        
                           
                    },{
                        headers:{
                            'token': cookie.auth
                        }
                               
                        }
                    );
                    
                }
            
                
                
        
                    //console.log(enrollData.data)
                    
                    setLoading(false)
                    alert("등록되었습니다.")
                    
                    window.location.reload();
                    
                

            }catch{
                setLoading(false)
                //console.log("등록 실패" )
                alert("등록이 실패하였습니다.")

            }
        
        }

        
        
      }
        

    return(
            <>
              
                 <Block>
                    
                 {cookie.name.split(" ")[0] == "B0001111" && <>
                    
                    <button onClick={()=>setPolymer(true)}>폴리머</button> <button onClick={()=>setPolymer(false)}>모노머</button> 
                    
                    
              </>}
              
                 <table className='customers'>
                    
                        <tr>

                            {polymer == true ? <>
                                <td colSpan='2' style={{textAlign:'center'}}>
                                일반 배차처리 
                             
                            </td>
                            </> : <>
                                <td colSpan='2' style={{textAlign:'center'}}>
                                모노머 배차처리 
                                
                                </td>
                            
                            </>}
                            
                            
                           
                        </tr>
                    
                    
                        <tr>
                            <td>
                                주문번호 
                            </td>
                            <td>
                                {data != null ?
                                    <input type='text' name='orderNumberText' onChange={onChange}  value={data.sapordernumber} /> :
                                    <input type='text' name='orderNumberText' onChange={onChange}   />
                                }
                                
                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                운송회사 
                            </td>
                            {cookie.name.split(" ")[0] == "B0001111" ? <>
                                <td>
                                    <select name="transportVendorText" onChange={onChange}>
                                        <option value="">운송사를 선택해주세요</option>
                                        <option value="B0018453_태양">태양</option>
                                        <option value="B0018534_서평">서평</option>
                                        <option value="B0006351_세창">세창</option>
                                        <option value="B0017822_정암">정암</option>
                                        <option value="B0000319_대륜">대륜</option>
                                        <option value="B0017815_신동아">신동아</option>
                                        <option value="B0018399_호남">호남</option>
                                        <option value="B0010631_토탈지엘에스">토탈지엘에스</option>
                                        <option value="B0011732_기양">기양</option>
                                        <option value="B0018464_제일화물">제일화물</option>
                                        <option value="B0023030_미화">미화</option>
                                        <option value="B0016476_우경로직스">우경로직스</option>
                                    </select>
                                </td>
                                
                            
                            </>:<>
                                <td>
                                    <input type='text' name='transportVendorText' onChange={onChange} value={cookie.name} />
                                </td>
                            
                            </>}
                           


                            
                        </tr>

                        {cookie.name.split(" ")[0] == "B0001111" && <>
                                {polymer == false && <>
                                    <tr>
                                        <td>
                                            주문수량
                                        </td>
                                        <td>
                                        <input type="text" name="orderAmount" autoComplete='off' placeholder="주문 수량" defaultValue={0} onChange={onChange} />
                                        </td>
                                    </tr>
                                
                                </> }
                        
                        </>}
                        <tr>
                            <td>
                                차량번호 
                            </td>
                            <td>
                            <input type="text" name="transportNumberText" list="carlistdata" autoComplete='off' placeholder="차량을 검색해주세요" onChange={(e)=>GetCarList(e)} />
                  
                            <datalist id="carlistdata">
                                {carlist != null && carlist.map((item, index) => (
                                <option value={`${item.transvendor}|${item.carnumber1} ${item.carnumber2} ${item.carcode}`} key={index}>{`${item.transvendor}|${item.carnumber1} ${item.carnumber2} ${item.carcode}`}</option>
                                ))}
                            </datalist>  
                                
                            </td>
                        </tr>
                        <tr>
                            <td>
                                출하요청일 
                            </td>
                            <td>
                            <DatePicker selected={orderDateText} onChange={(date) => setOrderDateText(date)} dateFormat="yy/MM/dd"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                도착요청일 
                            </td>
                            <td>
                            <DatePicker selected={orderArriveDateText} onChange={(date) => setOrderArriveDateText(date)} dateFormat="yy/MM/dd"/>
                            </td>
                        </tr>
                        
                        
                            
                       
                    
                    
                 </table>

                 <button className='btn' onClick={()=>submitData()} >차량배차</button>

                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index