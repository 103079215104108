import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';

import { BarChart, Bar, Brush, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {  LineChart,  Line,  ReferenceLine,} from 'recharts';
import { PieChart, Pie, Sector,  } from 'recharts';
import { AreaChart, Area  } from 'recharts';

import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import { StatusCriticalSmall,CaretRightFill, Analytics,Optimize,Launch, Search, Announce, Technology } from 'grommet-icons';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import Write from './Write';
import ChartDraw from './ChartDraw';

import { button, Modal,  } from 'antd';


const Block = styled.div`
  overflow:auto;

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }
  tr:hover {background-color: lightgrey;}

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      width:'60%',
      height:'60%'

      
  },
  border:{
    border: 'solid'
  }
  

 
  
}


const Electric = ({location}) => {
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)
  const [tagSearchText, setTagSearchText] = useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [modyOn,setModyOn] = useState(false)
    
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)

    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(20);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    //console.log(startDate)
    
    //console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  const tagFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      
      if(refData.tag.match(new RegExp(tagSearchText,"i"))){
        return true;
      }
    })
    
    setDatas(filterData)
    setFilterOn(true);
  })


  const dataSort = useCallback(()=>{
    //내림차순 정렬 
    if(filterOn){
      const sorted_list = datas.sort((a,b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      //console.log('filter On')
      setDatas(sorted_list)
      setTotalCount(sorted_list.length)

    }else{
      const sorted_list = datas.sort((a,b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      //console.log('filter On')
      setDatas(sorted_list.slice((page-1)*pagelimit,(page*pagelimit)))
      setTotalCount(sorted_list.length)
    }
  })

  const itemSort = useCallback((tagItem) =>{
    //console.log(tagItem)
    const filterData = refDatas.filter(refData => {
      
      if(refData.tag == tagItem){
        return true
      }
    })

    //console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);

  })


  

    const [ tableIndex, setTableIndex ] = useState(null)


    const [form, setForm] = useState ({})

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {
    //console.log(cookies.auth)
    fetchGetData();
    //fetchLoginCheck();
    //fetchData();

  },[])

  



  const fetchGetData = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_SOCKET}/allplantdata?type=elec&location=${location}`,{
                headers:{
                    token : cookie.auth
                }
            }
        );

        setDatas(response.data.slice((page-1)*pagelimit,(page*pagelimit)))
        setRefDatas(response.data)
        setTotalCount(response.data.length)
        //console.log(response.data.length)

       


    }catch (e) {
        //console.log(e);

       
    }
  }




  

  const doneWrite = () => {
    //alert("작성되었습니다.")
    
    if(window.confirm("제출하시겠습니까?")){
        
     
             //캔버스 저장 및 평가 결과 
            //save()           

            //fetchData()
            alert("등록되었습니다.")

            setIsOpen(false)
            //fetchGetData()
            
            
            
    
    }else{
        //취소
        return false
    }
   
    
}



  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    //console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    //console.log("close")
}


const chartData = (inChargePerson) =>{
  const xLabel = [];
  const yData = [];
  
  
    for (let i = 0; i < datas.length; i++) {
      if(datas[i].rotationVib != 'undefined' ){
        xLabel.push(datas[i].createdAt.split('T')[0])
        
        if(inChargePerson == "AMP"){
          yData.push(datas[i].operAmp)
        }else if(inChargePerson == "DE_V"){
          yData.push(datas[i].deVibV)
        }else if(inChargePerson == "DE_H"){
          yData.push(datas[i].deVibH)
        }else if(inChargePerson == "DE_A"){
          yData.push(datas[i].deVibA)
        }else if(inChargePerson == "NDE_V"){
          yData.push(datas[i].ndeVibV)
        }else if(inChargePerson == "NDE_H"){
          yData.push(datas[i].ndeVibH)
        }else if(inChargePerson == "NDE_A"){
          yData.push(datas[i].ndeVibA)
        }


      }
     
        //yDatas.push(datas[i].rotationVib)
    }

  
  
  return [xLabel, yData]
}



 

    return(

      

      
            <Block>

           <Modal title="Chart" open={modalIsOpen} onCancel={closeModal} footer={null} >
             
              {datas != null && <ChartDraw chartData={chartData(inChargePerson)} name={inChargePerson} />}

            </Modal>

            {!writeOn && <>
               {!modyOn && <button type='primary' onClick={() => setWriteOn(true)}>입력하기</button> }
              </>
              
            } 

           
            {writeOn && 
              <button   onClick={()=>setWriteOn(false)}>뒤로가기</button>
            }

            {modyOn &&
              <button onClick={() => setModyOn(false)}>뒤로가기</button>
            }


         

            {writeOn && <Write location={location} refCategory={category} />}

              
               {modyOn ? <><Write location={location} id={idInform} refCategory={category} /> </> : <>
                      
             
              <div>
                
                <table>
                <p/>
                      <div style={{float:'left'}}>
                            <table className='subTable'>
                              <tr>
                                <th rowSpan={2}>
                                  Grease & Oil
                                </th>
                                <th>
                                  상태
                                </th>
                                <th>
                                  양호 : O
                                </th>
                                <th>
                                  부족 : S
                                </th>
                                <th>
                                  불량 : X
                                </th>
                                <th>
                                  누류 : L
                                </th>
                                
                              </tr>
                              <tr>
                                <th>
                                  조치
                                </th>
                                <th>
                                  보충 : S
                                </th>
                                <th>
                                  교체 : E
                                </th>
                                <th>
                                  수리 : R
                                </th>
                                <th>
                                  청소 : C
                                </th>
                              </tr>
                              <tr>
                                <th colSpan={2}>
                                  BRG 이음/ 진동상태 
                                </th>
                                <th>
                                  양호 : O
                                </th>
                                <th>
                                  요주의 : 8
                                </th>
                                <th>
                                  심함 : X
                                </th>
                                
                              </tr>
                            </table>
                          
                      </div>

                      <div  style={{float:'right'}}>
                          <table className='subTable'>
                            <tr>
                              <th>
                                담당자
                              </th>
                              <th >
                                책임
                              </th>
                              <th>
                                리더
                              </th>

                            </tr>
                            <tr>
                              <th  width='80px' height='80px'>
                                
                              
                              </th>
                              <th  width='80px' height='80px'>
                              
                              </th>
                              <th  width='80px' height='80px'>
                              
                              </th>
                            </tr>
                            
                          </table>
                          
                      </div>



                      <div  style={{clear:'both'}}>
                        <hr/>
                      </div>

                      <div  style={{display:'inline-block'}}>
           
               <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/> 
           

                  </div>
                  <div  style={{display:'inline-block'}}>
                
                
                  ~

                  </div>
                  
                  <div  style={{display:'inline-block'}}>
            
            
              <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="yy-MM-dd"/>

                  
              

                  </div>
                  <div  style={{display:'inline-block', marginBottom : '30px'}}>
                
                      
                      <button   onClick={() => {dateFilter()}}> 검색</button>
                      


                  </div>

                  &emsp;&emsp;&emsp;Tag : <input type='text' name='tagSearchText' onChange={(e)=>setTagSearchText(e.target.value)} /><button   onClick={() => {tagFilter()}}>검색</button>
                  
                  
                  <br/>
                  &nbsp;<button  onClick={()=>{fetchGetData()}}>새로고침</button>&nbsp;
              <button  onClick={()=>window.print()}>인쇄하기</button>

              <div style={{clear:'both'}}>

              </div>

                      <table style={{width:'98%'}}>
                        <tr >
                        <th rowSpan={2}>
                            No
                          </th>
                        <th rowSpan={2} onClick={()=>dataSort()}>
                            점검일
                          </th>
                          <th rowSpan={2}>
                            Item No.
                          </th>
                          <th rowSpan={2}>
                            ItemName
                          </th>
                          <th colSpan={4}>
                            Rating(정격)
                            
                          </th>
                          <th rowSpan={2}>
                            등급
                          </th>
                          <th rowSpan={2} onClick={()=>{openModal("AMP")}}>
                            운전전류 <br/>[A]
                          </th>
                          <th rowSpan={2}>
                            절연저항 <br/>[MQ]
                          </th>
                          <th colSpan={3}>
                            DE Vibration
                            <br/>[mm/s]
                          </th>
                          <th colSpan={3}>
                            NDE Vibration
                            <br/>[mm/s]
                          </th>
                          <th colSpan={2}>
                            Grease 보충량(g)/<br/>주기(M)
                          </th>
                          <th colSpan={2}>
                            Grease 주유/<br/>OIL 상태
                          </th>
                          <th colSpan={2}>
                            Bearing<br/>Sound(이음)
                          </th>
                          <th rowSpan={2}>
                            진동<br/> 기준치<br/>Velocity<br/>(RMS)<br/>mm/s
                          </th>
                          <th rowSpan={2}>
                            Grease<br/>Oil Type
                          </th>
                          <th rowSpan={2}>
                            Remarks
                          </th>

                        </tr>

                        <tr >
                          <th>
                            KW
                          </th>
                          <th>
                            V
                          </th>
                          <th>
                            A
                          </th>
                          <th>
                            극수
                            
                          </th>
                          <th onClick={()=>{openModal("DE_V")}}>
                            V
                          </th>
                          <th onClick={()=>{openModal("DE_H")}}>
                            H
                          </th>
                          <th onClick={()=>{openModal("DE_A")}}>
                            A
                          </th>
                          <th onClick={()=>{openModal("NDE_V")}}>
                            V
                          </th>
                          <th onClick={()=>{openModal("NDE_H")}}>
                            H
                          </th>
                          <th onClick={()=>{openModal("NDE_A")}}>
                            A
                          </th>
                          <th>
                            DE
                          </th>
                          <th>
                            NDE
                          </th>
                          <th>
                            DE
                          </th>
                          <th>
                            NDE
                          </th>
                          <th>
                            DE
                          </th>
                          <th>
                            NDE
                          </th>

                        </tr>
                      {datas !=null && datas.map((data,index)=> <>
                        <tr key={index}>
                          <td onClick={()=>{setModyOn(true), setIdInform(data._id)}}>
                              {index}
                            </td>
                          <td>
                            {data.createdAt.split("T")[0]}
                            </td>
                            
                            <td onClick={() => {itemSort(data.tag)}}>
                            {data.tag != 'undefined' ? data.tag : '-'} 
                            </td>
                            <td>
                            {data.itemName != 'undefined' ? data.itemName : '-'} 
                            </td>
                            <td>
                            {data.ratingKW != 'undefined' ? data.ratingKW : '-'}
                            </td>
                            <td>
                            {data.ratingV != 'undefined' ? data.ratingV : '-'}
                            </td>
                            <td>
                            {data.ratingA != 'undefined' ? data.tratingAag : '-'}
                            </td>
                            <td>
                            {data.ratingPole != 'undefined' ? data.ratingPole : '-'}
                            </td>
                            <td>
                            {data.grade != 'undefined' ? data.grade : '-'}
                            </td>
                            <td>
                            {data.operAmp != 'undefined' ? data.operAmp : '-'}
                            </td>
                            <td>
                            {data.operResist != 'undefined' ? data.operResist : '-'}
                            </td>
                            <td>
                            {data.deVibV != 'undefined' ? data.deVibV : '-'}
                            </td>
                            <td>
                            {data.deVibH != 'undefined' ? data.deVibH : '-'}
                            </td>
                            <td>
                            {data.deVibA != 'undefined' ? data.deVibA : '-'} 
                            </td>
                            <td>
                            {data.ndeVibV != 'undefined' ? data.ndeVibV : '-'}
                            </td>
                            <td>
                            {data.ndeVibH != 'undefined' ? data.ndeVibH : '-'}
                            </td>
                            <td>
                            {data.ndeVibA != 'undefined' ? data.ndeVibA : '-'}
                            </td>
                            <td>
                              {data.greaseMakeDE != 'undefined' ? data.greaseMakeDE : '-'}  
                            </td>
                            <td>
                              {data.greaseMakeNDE != 'undefined' ? data.greaseMakeNDE : '-'}
                            </td>
                            <td>
                              {data.greaseOilDE != 'undefined' ? data.greaseOilDE : '-'}
                            </td>
                            <td>
                              {data.greaseOilNDE != 'undefined' ? data.greaseOilNDE : '-'}
                            </td>
                            <td>
                              {data.bearingDE != 'undefined' ? data.bearingDE : '-'}
                            </td>
                            <td>
                              {data.bearingNDE != 'undefined' ? data.bearingNDE : '-'}
                            </td>
                            <td>
                              {data.rms != 'undefined' ? data.rms : '-'}
                            </td>
                            <td>
                              {data.greaseOilType != 'undefined' ? data.greaseOilType : '-'}
                            </td>
                            <td>
                              {data.remarks != 'undefined' ? data.remarks : '-'}
                            </td>
                        </tr>

                      </>)}
                      


                      </table>

                     
                      
                
                  
                 
                </table>

                {filterOn == false && 
                    <Pagination
                    activePage={page}
                    itemsCountPerPage={pagelimit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={"<"}
                    nextPageText={">"}
                    onChange={handlePageChange}
                    />

                 }
              </div>

             

              <div style={{clear:'both', margin:'20px'}}>

              </div>

              </>}

              
                  
                    
                    
                    

              

               
              
            </Block>
    
        );
    
   
};

export default Electric