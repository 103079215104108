import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';

import axios from 'axios';


import { Route, Link, Switch  } from 'react-router-dom';
import Main from './components/Main';

import Agreement from './components/Agreement';
import Sales from './components/Sales';
import Login from './components/Login';

import PM from './components/PM';
import PMwrite from './components/PM/Write';
import WorkBeforeCheck from './components/WorkBeforeCheck';
import WorkSurvey from './components/WorkSurvey';
import FireCheck from './components/FireCheck'


//import { Calendar } from 'react-big-calendar';



const App = () => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
 
  const agreePath = process.env.REACT_APP_ROUTE1 || "/agreement/:location/:section";
  const salesPath = process.env.REACT_APP_ROUTE2 || "/sales";
  const pmPath = process.env.REACT_APP_ROUTE3 || "/pm/:location";
  const pmwritePath = process.env.REACT_APP_ROUTE4 || "/pmwrite/:location";
  const workbeforePath = process.env.REACT_APP_ROUTE5 || "/workbeforecheck/:ver/:location/:division/:type/:equipname";
  const worksurveyPath = process.env.REACT_APP_ROUTE6 || "/worksurvey/:ver/:location";
  const firecheckPath = process.env.REACT_APP_ROUTE7 || "/firecheck/:ver/:site/:division/:equipname/:managenumber/:location/:dlocation/:type";
  const loginPath = process.env.REACT_APP_ROUTE8 || "/login" ;
  
  

  return(
    
    <Switch>
      <Route path="/" component = {Main} exact={true} />     
      
      <Route path={agreePath} component = {Agreement} />
      
      
      <Route path={salesPath} component = {Sales} />
      
      <Route path={pmPath} component = {PM} />
      <Route path={pmwritePath} component = {PMwrite} />
      <Route path={workbeforePath} component = {WorkBeforeCheck} />
      <Route path={worksurveyPath} component = {WorkSurvey} />
      <Route path={firecheckPath} component = {FireCheck} />
      

      <Route path={loginPath} component = {Login} />
      
      
      
     
      <Route
        render ={({ location }) => (
          <div>
            <h2>이 페이지는 존재하지 않습니다.. </h2>

          </div>
        )}/>
        
    </Switch>
    
    
    

  );




};

export default App;

