
import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';



const Block = styled.div`
    display:flex;
    flex-direction : column;
    font-size: 12px ;
    
    table {
        border-collapse: collapse;
        width : 98%;
    }
  
   
    table, td, th {
        border: 1px solid #ddd;
        text-align: left;
        
    }
    
    tr:nth-child(even){background-color: #f2f2f2}
      
    tr:hover {background-color: #F8F8FF;}

    th  {
        background-color: #6495ED;
        color: white;
        
        
    }

    th, td{
        min-width: 30px ;
        text-align: center;
    }

    .content {
        
    }

    .btnAuth {
        border-radius : 5px;
        width : 80px;
        
    }
   
    .btnSubmit {
        border-radius : 5px;
        width : 100%
        
    }
    
    .center{
        text-align: center ;
    }

    input{
        width: 80%;
    }

    button{
        height: 60px;
        background-color: gray ;
        color: white;
        font-size: 20px;
    }
    
   
   

`



const Robot = ({data}) => {
    
    
    //console.log(location)

    const [startDate, setStartDate] = useState(new Date());

    
    const [form, setForm] = useState ({
        equipname : '',
        worker : '',
        comment : '-'
    })

    const onChange = useCallback(e => {
    
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
    },[form]);
    
    
    
    const [checkedList, setCheckedList] = useState([]);//적합
    const [uncheckedList, setUnCheckedList] = useState([]);//부적합 
    const [noneList, setNoneList] = useState([]);//해당없음 
     
    const onCheckBoxChange = useCallback(e => {
        const checkboxes = document.getElementsByName(`${e.target.name}`)

        for (let i=0; i< checkboxes.length; i++){
            

            if(checkboxes[i] !== e.target){
                checkboxes[i].checked = false
                
            }

            
        }

        setCheckedList([...checkedList, e.target.name]);

        if(e.target.value == "1" && e.target.checked){
            //console.log("적합")
            setCheckedList([...checkedList, e.target.name]);
            setUnCheckedList(uncheckedList.filter(el => el !== e.target.name));
            setNoneList(noneList.filter(el => el !== e.target.name));
            
        }else if(e.target.value == "2" && e.target.checked){
            //console.log("부적합")
            setCheckedList(checkedList.filter(el => el !== e.target.name));
            setUnCheckedList([...uncheckedList, e.target.name]);
            setNoneList(noneList.filter(el => el !== e.target.name));
            //console.log("미동의")
        }else if(e.target.value == "3" && e.target.checked){
            //console.log("해당없음")
            setCheckedList(checkedList.filter(el => el !== e.target.name));
            setUnCheckedList(uncheckedList.filter(el => el !== e.target.name));
            setNoneList([...noneList, e.target.name]);
            
        }else{
            setCheckedList(checkedList.filter(el => el !== e.target.name));
            setUnCheckedList(uncheckedList.filter(el => el !== e.target.name));
            setNoneList(noneList.filter(el => el !== e.target.name));
            
        }

        //console.log('value')
        //console.log(checkedList)
        //console.log(uncheckedList)
        //console.log(noneList)

        

      
       
        
      },);

    
    const Submit = useCallback(async() => {

        if(form.equipname == null || form.worker == "" ){
            alert("설비명, 점검자를 확인해주세요.")
            return;
        }

        if(checkedList.length + uncheckedList.length  + noneList.length !=8){
            alert("빈항목이 존재합니다.")
            return;
        }

        

        if(window.confirm("제출할까요?")){
         
            try{
                const fetchData = await axios.post(
                    `${process.env.REACT_APP_HOST}/sens/workbeforecheck?version=${data.ver}&location=${data.location}&division=${data.division}&type=${data.type}&equipname=${data.equipname}&worker=${form.worker}&comment=${form.comment}`,{
                                checkedList : checkedList,
                                uncheckedList : uncheckedList,
                                noneList : noneList
                    },
                    {
                        headers:{
                            //token:process.env.REACT_APP_ACCESS_WEDO_TOKEN
                        }
                    }
                );
    
                
                
                
                //console.log(fetchData.data)
                alert("제출되었습니다.")
                window.location.reload()
                
        
            }catch (e) {
                //console.log(e);
                
            }




                
            
            
            

        }else{
            alert("취소되었습니다.")
        }
        
    },)
   
        

    return(
            <>
              
                    <Block>
                 
                
                    <div >
                        <h3>{data.location == "yeosu" ? "여수" : "대산"}</h3>
                        <h2>산업용 로봇 작업 시작 전 점검</h2>

                        <table>
                            
                               
                                <tr>
                                    <td >
                                        설비명
                                    </td>
                                    <td>
                                        <label>{data.equipname}</label>
                                        
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        점검자
                                    </td>
                                    <td>
                                        <input type="text" name='worker' onChange={onChange} />
                                        
                                    </td>
                                </tr>
                                
                                
                           
                        </table>
                        <p/>
                        <hr/>
                        <p/>
                        <table>
                            <tr>
                                <td width={'10%'}>
                                    <strong>No.</strong>
                                </td>
                                <td width={'50%'}>
                                    <strong>점검항목</strong>
                                </td>
                                <td colSpan={3} width={'40%'}>
                                    <strong>점검결과</strong>
                                </td>
                               
                            </tr>
                            <tr>
                                <td>
                                    1.
                                </td>
                                <td>
                                    외부전선의 피복 또는 외장의 손상 유무
                                    <br/>
                                </td>
                                <td>
                                    <input type="checkbox" name="agree1" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree1" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree1" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            
                            <tr>
                                <td>
                                    2.
                                </td>
                                <td>
                                    매니플레이터(manipulator)작동의 이상 유무 
                                    <br/>
                                </td>
                                <td>
                                    <input type="checkbox" name="agree2" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree2" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree2" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    3.
                                </td>
                                <td>
                                    제동장치 및 각 비상정지장치의 기능
                                    <br/>
                                </td>
                                <td>
                                    <input type="checkbox" name="agree3" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree3" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree3" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    4.
                                </td>
                                <td>
                                    방책의 설치상태 이상 유무
                                </td>
                                <td>
                                    <input type="checkbox" name="agree4" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree4" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree4" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    5.
                                </td>
                                <td>
                                    안전매트 기능의 이상 유무
                                </td>
                                <td>
                                    <input type="checkbox" name="agree5" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree5" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree5" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    6.
                                </td>
                                <td>
                                    출입문 개방 시 로봇이 정지하도록 안전  플러그등 연동장치의 설치 및 이상 유무
                                </td>
                                <td>
                                    <input type="checkbox" name="agree6" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree6" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree6" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    7.
                                </td>
                                <td>
                                    전기장치의 접지 상태
                                </td>
                                <td>
                                    <input type="checkbox" name="agree7" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree7" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree7" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    8.
                                </td>
                                <td>
                                    주변 정리정돈 상태 
                                </td>
                                <td>
                                    <input type="checkbox" name="agree8" value="1" onChange={ onCheckBoxChange } /> <br/>적 합
                                            
                                </td>
                                <td>
                                    <input type="checkbox" name="agree8" value="2" onChange={onCheckBoxChange} /> <br/>부적합

                                </td>
                                <td>
                                    <input type="checkbox" name="agree8" value="3" onChange={onCheckBoxChange} /> <br/>해당없음
                                </td>
                            </tr>
                            <tr height="40px">
                                <td > 
                                    비고
                                </td>
                                <td colSpan={4}>
                                    <input type="text" name='comment' onChange={onChange} />
                                </td>
                            </tr>
                           
                        </table>

                        <hr/>

                            <button className='btnSubmit' onClick={Submit}>등록하기</button>
                    </div>
                
                
             
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default Robot