import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';


import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import Write from './Write';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const Block = styled.div`
  overflow:auto;

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      
  },
  border:{
    border: 'solid'
  }
  

 
  
}



Modal.setAppElement('#root')
const DistPanelCheckWrite = ({_id, location, refCategory}) => {
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [selectDate, setSelectDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)


    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const [form, setForm] = useState ({
   

  })

  const onChangeComment= useCallback((name,comment) => {
    
    //console.log(name)
    const nextForm = {
      ...form,
      [name]: comment
    };

    
    setForm(nextForm);
   
    //console.log(form)
    
},[form]);


  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    //console.log(startDate)
    
    //console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  

    const [ tableIndex, setTableIndex ] = useState(null)

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {
    
    if (_id != null){
      //쓰기가 아닐경우 개별 아이디 조회 
      fetchGetData(_id);
      

    }

  },[])

  



  const fetchGetData = async(_id) => {

    try{
      
      const response = await axios.get(
        `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}`,{
            headers:{
                token : cookie.auth
            }
          }
      );

      setDatas(response.data[0])
      setRefDatas(response.data[0])
      setTotalCount(response.data[0].length)
      setSelectDate(new Date(new Date(response.data[0].createdAt) - 32400000))
      setForm(response.data[0].dataList[0])
      //console.log(response.data[0])

       


    }catch (e) {
        //console.log(e);

       
    }
  }


  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            history.push("/login")
        }

        //


    }catch (e) {
        //console.log(e);

        history.push("/login")
       
    }
  }



  

  const doneWrite = async() => {
    //alert("작성되었습니다.")
    
    try{
      
      const response = await axios.post(
        `${process.env.REACT_APP_SOCKET}/plantdata?type=${refCategory}&date=${startDate}&location=${location}`,{
            dataList : form,
            headers:{
                'token': cookie.auth
            }
        });
        alert("등록되었습니다.")
        
        window.location.reload();
        
        //form.clear()
        
    
      //console.log(response.data.length)

     


    }catch (e) {
        //console.log(e);
        alert(e)
      
    }
   
    
}
//서명 클리어
const clear = () => {
    //캔버스 클리어
    signCanvas.current.clear();
}
//서명 저장
const save = () => {
    
    //console.log("캔버스 저장")
    
    const dataURL = signCanvas.current.toDataURL('image/png')
    const decodeURL = dataURL.replace(/^data:image\/\w+;base64,/,'');
    const buf = Buffer.from(decodeURL, 'base64')
    const blob = new Blob([buf], {type:'image/png'})
    const file = new File([blob], `${v4()}.png`,{type:'image/png'})
    //console.log(file)
    
    const formData = new FormData();
    formData.append('sign', file)

    /*
    axios({
        baseURL : process.env.REACT_APP_HOST,
        url: '/pm/uploadsignimg',
        method: 'POST',
        data: formData,
        headers:{
            'Content-Type' : 'multipart/form-data',              
        },
    })
        .then(response => {
            //console.log(response.data)
            //console.log(response.data.originalname)
            
            //setReturnOriginalFilename(response.data.originalname)
            //console.log(response.data.filename)
            if(inChargePerson == '담당자'){
              setSignPath1(response.data.filename)
            }else if(inChargePerson == '책임'){
              setSignPath2(response.data.filename)
            }else if(inChargePerson == '리더'){
              setSignPath3(response.data.filename)
            }
            //enrollResult(response.data.filename)

                           
            

        })
        .catch(error => {
            console.error(error)
        })

    */
   
        
    
}


const doneMody = async() => {
  //alert("작성되었습니다.")
  
  
  
  if(window.confirm("수정할까요?")){
      
   
           //캔버스 저장 및 평가 결과 
          //save()           

        try{
    
            const response = await axios.put(
              `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}&type=${refCategory}&date=${selectDate - 32400000 }&location=${location}`,{
                  dataList : form,
                  headers:{
                      'token': cookie.auth
                  }
              });
              alert("수정되었습니다..")
              
              window.location.reload();
              
              //form.clear()
              
          
            //console.log(response.data.length)
    
           
    
    
        }catch (e) {
            //console.log(e);
            alert(e)
           
        }

          //fetchData()
          

          //setIsOpen(false)
          //fetchGetData()
          
          
          
  
  }else{
      //취소
      return false
  }
 
  
}




  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    //console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    //console.log("close")
}


const table = (data) => {
  const textHtml= []

  if (data ==null ){

  for (let i =1; i < 25; i++){
    textHtml.push(<tr style={{height:'40px'}}>
      <td> {i} </td>
      <td> <input type='text' name={'sub'+ i + (i)} onChange={onChange}/> </td>
      <td> <input type='text' name={'sub'+ i + (i+1)} onChange={onChange}/> </td>
      <td> <input type='text' name={'sub'+ i + (i+2)} onChange={onChange}/> </td>
      <td>  <input type='text' name={'sub'+ i + (i+3)} onChange={onChange}/> </td>
      <td> <input type='text' name={'sub'+ i + (i+4)} onChange={onChange}/> </td>
      
     
      </tr>)
    
  }
  return textHtml
  
  }else{

    for (let i =1; i < 25; i++){
      textHtml.push(<tr style={{height:'40px'}}>
        <td> {i} </td>
        <td> <input type='text' name={'sub'+ i + (i)} defaultValue={data.dataList[0]['sub'+i+(i)]} onChange={onChange}/> </td>
      <td> <input type='text' name={'sub'+ i + (i+1)} defaultValue={data.dataList[0]['sub'+i+(i+1)]} onChange={onChange}/> </td>
      <td> <input type='text' name={'sub'+ i + (i+2)} defaultValue={data.dataList[0]['sub'+i+(i+2)]} onChange={onChange}/> </td>
      <td>  <input type='text' name={'sub'+ i + (i+3)} defaultValue={data.dataList[0]['sub'+i+(i+3)]} onChange={onChange}/> </td>
      <td> <input type='text' name={'sub'+ i + (i+4)} defaultValue={data.dataList[0]['sub'+i+(i+4)]} onChange={onChange}/> </td>
      
        
       
        </tr>)
      
    }
    return textHtml
    
  }
}
 

 

return(

  
        <Block>
            {_id == null ? <>
              <div>
                    
                    <table>
                      <tr>
                        <td colspan="2" >
                          <div  style={{float:'left'}}>
                            Location : <input type='text' name='location' onChange={onChange}/> &emsp;&emsp;&emsp; Panel No : <input type='text' name='panel' onChange={onChange} /> 
                          </div>
                        </td>
                      </tr>
                      
                      <tr>
                        
                        <td colspan='2'> 
                        
                        <div  style={{float:'left'}}>
                          Date : &emsp;&emsp;
                          </div>
                          <div  style={{float:'left'}}>
                          <DatePicker style={{float:'left'}} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/>  
                          </div>
                          <div style={{float:'right'}}>
                            점검자 : <input type='text' name='checker' onChange={onChange}></input> &emsp;&emsp;
                          </div>
                          
                        </td>
                        
                        
                      </tr>
                      <tr>
                        <td style={{width:'100px'}}>
                          상태표시 
                        </td>
                        <td>
                          정상 : ○  &emsp;&emsp; 요주의 : △ &emsp;&emsp; 불량 : x &emsp;&emsp; 이음 : N &emsp;&emsp; 냄새 : R &emsp;&emsp; 발열 : H
                        </td>
                        
                      </tr>
                     
                    </table>
                  </div>
              
                  <div>
                    <table>
                      <tr>
                        <th>
                          CIRCUIT<br/> NO
                        </th>
                        <th>
                          LOAD<br/> CURRENT [ A ]
                        </th>
                        <th>
                          차단기 & Cable <br/>
                          상태
                        </th>
                        <th>
                          열화상 점검
                        </th>
                        <th>
                          Panel<br/> 내부상태
                        </th>
                        <th>
                          REMARKS
                        </th>
                      
                        
                      </tr>
                      {table(null)}
                      <tr>
                            <td>특기사항</td>
                            <td colspan='11' style={{textAlign:'left'}} >
                              <CKEditor
                              
                              editor={ ClassicEditor }
                              onChange={(event,editor)=>{
                                  onChangeComment("comment",editor.getData())
                              }}
                              />
                            </td>
                        </tr>
                    </table>
                  </div>




                  <div >
                    
                    <button onClick={() => doneWrite()}>등록하기</button>
                  </div>
            
            </> :<>
            {datas != null && <>
              <div>
                    
                    <table>
                      <tr>
                        <td colspan="2" >
                          <div  style={{float:'left'}}>
                            Location : <input type='text' name='location' defaultValue={datas.dataList[0].location} onChange={onChange} /> &emsp;&emsp;&emsp; Panel No : <input type='text' name='panel' defaultValue={datas.dataList[0].panel} onChange={onChange} /> 
                          </div>
                        </td>
                      </tr>
                      
                      <tr>
                        
                        <td colspan='2'> 
                        
                        <div  style={{float:'left'}}>
                          Date : &emsp;&emsp;
                          </div>
                          <div  style={{float:'left'}}>
                    <DatePicker selected={selectDate} onChange={(date) => setSelectDate(date)} dateFormat="yy-MM-dd" />
                          </div>
                          <div style={{float:'right'}}>
                            점검자 : <input type='text' name='checker' defaultValue={datas.dataList[0].checker} onChange={onChange}></input> &emsp;&emsp;
                          </div>
                          
                        </td>
                        
                        
                      </tr>
                      <tr>
                        <td style={{width:'100px'}}>
                          상태표시 
                        </td>
                        <td>
                          정상 : ○  &emsp;&emsp; 요주의 : △ &emsp;&emsp; 불량 : x &emsp;&emsp; 이음 : N &emsp;&emsp; 냄새 : R &emsp;&emsp; 발열 : H
                        </td>
                        
                      </tr>
                     
                    </table>
                  </div>
              
                  <div>
                    <table>
                      <tr>
                        <th>
                          CIRCUIT<br/> NO
                        </th>
                        <th>
                          LOAD<br/> CURRENT [ A ]
                        </th>
                        <th>
                          차단기 & Cable <br/>
                          상태
                        </th>
                        <th>
                          열화상 점검
                        </th>
                        <th>
                          Panel<br/> 내부상태
                        </th>
                        <th>
                          REMARKS
                        </th>
                      
                        
                      </tr>
                      {table(datas)}
                      <tr>
                            <td>특기사항</td>
                            <td colspan='11' style={{textAlign:'left'}} >
                              <CKEditor
                              
                              editor={ ClassicEditor }
                              onChange={(event,editor)=>{
                                  onChangeComment("comment",editor.getData())
                              }}
                              />
                            </td>
                        </tr>
                    </table>
                  </div>




                  <div >
                    
                    <button onClick={() => doneMody()}>수정하기</button>
                  </div>
            
            </>}
            
            </>}
            
                 
                
                
                
          
                          
          
        </Block>

    );
    
   
};

export default DistPanelCheckWrite