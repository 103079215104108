import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import {useCookies} from 'react-cookie';

const Block = styled.div`
    .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    }

    .customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
    min-width: 80px ;
    }

  

    .customers tr:nth-child(even){background-color: #f2f2f2;}

    .customers tr:hover {background-color: #ddd;}

    .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: white;
    }

    .customers img{
        text-align: center ;
        height : 300px;
    }

    .customers input {
        width: 90%;
    }
        
  

    .btn{
        width:100% ;
        height: 50px;
        background-color: lightgray;
        color: black ;
        font-size: 18px;
        
        
    }
`

const index = ({site}) => {
    
    const [cookie, setCookie, removeCookie] = useCookies(['auth','name']);
    
    //const size = match.params.location
   
    //const location = data.location
    //console.log(site)

    const [loading, setLoading] = useState(false)
    
    const [form, setForm] = useState ({
        carNumberText:'',
        carNameText:'',
        carPhoneNumberText:''


        
    })
    const [startDate, setStartDate] = useState(new Date());

    const onChange = useCallback(e => {
    
    
        const nextForm = {
          ...form,
          [e.target.name]: e.target.value
        };

        
        setForm(nextForm);
       
        
    },[form]);


      const submitData = async() => {
        
        if(form.carNumberText == ''   ){
            alert('빈 값이 존재합니다.')
            return;
        }


        if(window.confirm("등록할까요?")){
            setLoading(true)
        

            try{
                
             
                const title = "차량등록 " + form.carNumberText;

                if (cookie.name == "B0001111"){

                    if(form.carVendorText == undefined || form.carVendorText == ''   ){
                        alert('운송사를 선택해주세요')
                        return;
                    }else if (form.carNameText == ''){
                        alert('기사님 성함이 필요합니다.')
                        return;
                    }


                    //관리자 등록 
                    const rpadata = form.carVendorText.split("_")[0] + " " + form.carVendorText.split("_")[1] + " " +
                        form.carNumberText.replace(/(\s*)/g,"") + " " +
                        form.carNameText.replace(/(\s*)/g,"") + " " +
                        "0000";
                

                    const enrollData = await axios.post(
                    `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                        
                    },{
                        headers:{
                            'token': cookie.auth
                        }
                                
                        }
                    );

            
                    
                    setLoading(false)
                    alert("등록되었습니다.")
                    
                    window.location.reload();


                }else{
                    const rpadata = cookie.name.replace(/'/g,"") + " " +
                        form.carNumberText.replace(/(\s*)/g,"") + " " +
                        "Polymer" + " " +
                        "0000";
                

                    const enrollData = await axios.post(
                    `${process.env.REACT_APP_HOST_RPA}/rpa?title=${title}&rpadata=${rpadata}`,{
                        
                    },{
                        headers:{
                            'token': cookie.auth
                        }
                                
                        }
                    );

            
                    
                    setLoading(false)
                    alert("등록되었습니다.")
                    
                    window.location.reload();

                }
                
                    
                

            }catch{
                setLoading(false)
                //console.log("등록 실패" )
                alert("등록이 실패하였습니다.")

            }
        
        }

        
        
      }
        

    return(
            <>
              
                 <Block>
                 <table className='customers'>
                    
                 <tr>
                            <td colSpan='2' style={{textAlign:'center'}}>
                                차량등록 
                             
                            </td>
                            
                           
                        </tr>
                    
                    
                        <tr>
                            <td>
                                운송사 
                            </td>
                            {cookie.name == "B0001111" ? <>
                                <td>
                                        <select name="carVendorText" onChange={onChange}>
                                            <option value="">운송사를 선택해주세요</option>
                                            <option value="B0018453_태양">태양</option>
                                            <option value="B0018534_서평">서평</option>
                                            <option value="B0006351_세창">세창</option>
                                            <option value="B0017822_정암">정암</option>
                                            <option value="B0000319_대륜">대륜</option>
                                            <option value="B0017815_신동아">신동아</option>
                                            <option value="B0018399_호남">호남</option>
                                            <option value="B0010631_토탈지엘에스">토탈지엘에스</option>
                                            <option value="B0011732_기양">기양</option>
                                            <option value="B0018464_제일화물">제일화물</option>
                                            <option value="B0023030_미화">미화</option>
                                            <option value="B0016476_우경로직스">우경로직스</option>
                                        </select>
    
                                        
                                    </td>
                            
                            </> 
                            
                            : <>
                                <td>
                                    <input type='text' name='carVendorText' onChange={onChange} value={cookie.name} />
                                    
                                </td>
                            
                            </> }
                            
                        </tr>
                        <tr>
                            <td>
                                차량번호 
                            </td>
                            <td>
                                <input type='text' name='carNumberText' onChange={onChange} placeholder='충남68다3922' />
                            </td>
                        </tr>
                        {cookie.name == "B0001111" && 
                            <tr>
                                <td>
                                    운전자 
                                </td>
                                <td>
                                    <input type='text' name='carNameText' onChange={onChange} placeholder='홍길동' />
                                </td>
                            </tr>
                        }
                    
                 </table>

                 <button className='btn' onClick={()=>submitData()} >차량 등록</button>

                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index