import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';


import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';


import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const Block = styled.div`
  

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
   
  }
  
  select{
    width: 100px;
    height : 30px;
    text-align: center;
  }

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      
  },
  border:{
    border: 'solid'
  }

 
  

 
  
}



Modal.setAppElement('#root')
const Write = ({match, refCategory, idInform}) => {
  
  
  const refParams = match.params
  //console.log(refParams.ver)
   
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)
  const [limitDates, setLimitDates] = useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [selectDate, setSelectDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)


    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const [initComment, setInitComment] = useState(null)

  const [form, setForm] = useState ({
    list11 : '양호',
    list12 : '양호',
    list13 : '양호',
    list21 : '양호',
    list22 : '양호',
    list23 : '양호',
    list31 : '양호',
    list32 : '양호',
    list33 : '양호',
    list41 : '양호',
    list42 : '양호',
    list43 : '양호',
    list51 : '양호',
    list52 : '양호',
    list53 : '양호',
    list61 : '양호',
    list62 : '양호',
    list63 : '양호',
    list71 : '양호',
    list72 : '양호',
    list73 : '양호',
    list81 : '양호',
    list82 : '양호',
    list83 : '양호',
    list91 : '양호',
    list92 : '양호',
    list93 : '양호',
    list101 : '양호',
    list102 : '양호',
    list103 : '양호',
    list111 : '양호',
    list112 : '양호',
    list113 : '양호',
    list121 : '양호',
    list122 : '양호',
    list123 : '양호',
    list131 : '양호',
    list132 : '양호',
    list133 : '양호',
    list141 : '양호',
    list142 : '양호',
    list143 : '양호',
    list151 : '양호',
    list152 : '양호',
    list153 : '양호',
    list161 : '양호',
    list162 : '양호',
    list163 : '양호',
    comment : '',
    writer : ''


  })

  const onChangeComment= useCallback((name,comment) => {
    
    //console.log(name)
    const nextForm = {
      ...form,
      [name]: comment
    };

    
    setForm(nextForm);
   
    //console.log(form)
    
},[form]);


  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    //console.log(startDate)
    
    //console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  

    const [ tableIndex, setTableIndex ] = useState(null)

 

    const onChange = useCallback(e => {
    
    
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  

  useEffect(() => {
    //console.log(cookies.auth)
    
      //쓰기가 아닐경우 개별 아이디 조회 

      if (idInform != null){
        fetchGetData();
      }else{
        //여기서는 제조년월 조회 
        fetchGetLimitDate();
      }
      
      

  },[])

  


  const fetchGetLimitDate = async() => {

    try{
      
        
        // data 조회
        const response = await axios.get(
          `${process.env.REACT_APP_SOCKET}/firecheck/enrolldate?managenumber=${refParams.managenumber}`,{
              headers:{
                  token : cookie.auth
              }
            }
        );

  
        //console.log(response.data)
        setLimitDates(response.data.limitdate)
        
      
     

       


    }catch (e) {
        //console.log(e);

       
    }
  }



  const fetchGetData = async() => {

    try{
      
        //console.log("detail 조회1 ")
        // data 조회
        const response = await axios.get(
          `${process.env.REACT_APP_SOCKET}/firecheck/detail?id=${idInform}`,{
              headers:{
                  token : cookie.auth
              }
            }
        );

  
        //console.log(response.data)
        setDatas(response.data)
        //setRefDatas(response.data[)
        //setTotalCount(response.data[0].length)
        //setSelectDate(new Date(response.data[0].createdAt))
        setForm(response.data.bodyData[0])
        setInitComment(response.data.bodyData[0].comment)


        //console.log("제조년월 조회 ")
        
        setLimitDates(response.data.limitDate)
        
      
     

       


    }catch (e) {
        //console.log(e);

       
    }
  }

  

  const doneWrite = async() => {

  try{

    if (idInform == null){
      //등록하기
      //alert("등록할까요?")

      if(window.confirm("등록할까요?")){
        const response = await axios.post(
          `${process.env.REACT_APP_SOCKET}/firecheck?limitDate=${limitDates}`,{
              queryData : refParams,
              bodyData : form,
              headers:{
                  'token': cookie.auth
              }
          });
          alert("등록되었습니다.")
          //console.log(response.data)  
          window.location.reload();

        
      }else{
        alert("취소되었습니다.")
      }
    
    
      
      
        
        //form.clear()
        
    
      //console.log(response.data.length)

    }else{

      if(window.confirm("수정할까요?")){

      

      //수정하기 여기 확인 할것 
      const response = await axios.put(
        `${process.env.REACT_APP_SOCKET}/firecheck?id=${idInform}`,{
            queryData : refParams,
            bodyData : form,
            headers:{
                'token': cookie.auth
            }
        });
        alert("수정되었습니다..")
        
        window.location.reload();
      }else{
        alert("취소되었습니다.")
      }
        //form.clear()
        
    
      //console.log(response.data.length)



    }
    

     


    }catch (e) {
        //console.log(e);
        alert(e)
      
    }
   
    
}

const doneDelete = async() => {

  try{

    if (idInform == null){
      

    }else{
      //console.log(idInform)

      if(window.confirm("삭제할까요?")){
         //삭제하기
        const response = await axios.delete(
          `${process.env.REACT_APP_SOCKET}/firecheck?id=${idInform}`,{
             
              headers:{
                  'token': cookie.auth
              }
          });
          alert("삭제되었습니다..")
          
          window.location.reload();
          
        //form.clear()
        
    
      //console.log(response.data.length)



      }else{
        alert("취소되었습니다.")
      }

      

     



    }
    

     


    }catch (e) {
        //console.log(e);
        alert(e)
      
    }
   
    
}




  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    //console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    //console.log("close")
}


const View = (data) => {
  
  const textHtml= []

  //글쓰기
  
    if(refParams.equipname == "소화기"){
      textHtml.push(<table>
        <tr>
          <td>
            점검내용
          </td>
          <td>
            점검상태
          </td>
        </tr>
        <tr>
          <td>
            1. 압력상태
          </td>
          <td>
            <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11} >
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
           
          </td>        
        </tr>
        <tr>
          <td>
            2. 노즐상태
          </td>
          <td>
            <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            3. 안전표지
          </td>
          <td>
            <select name='list31' onChange={onChange} value={idInform == null ? null : form.list31}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            4. 외관상태
          </td>
          <td>
            <select name='list41' onChange={onChange} value={idInform == null ? null : form.list41}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            5. 박스, 커버, 받침대, 상태
            </td>
            <td>
              <select name='list51' onChange={onChange} value={idInform == null ? null : form.list51}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
  
            </td>
        </tr>
        <tr>
          <td>
            6. 기타 특이사항(불량사항 기입)
          </td>
          <td>
            <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment} />
          </td>
        </tr>
        
      </table>
        
      )
  
    }else if(refParams.equipname == "소화전"){
      textHtml.push(<table>
        <tr>
          <td colSpan={2} >
            점검내용
          </td>
          <td>
            점검상태
          </td>
        </tr>
        <tr>
          <td rowSpan='2'>
            1. 소화전
          </td>
          <td>
            Leak 여부
          </td>
          <td>
            <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            커넥터상태
          </td>
          <td>
            <select name='list12' onChange={onChange} value={idInform == null ? null : form.list12}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
          
        </tr>
        <tr>
          <td rowSpan='2'>
            2. 소화전 Box
          </td>
          <td>
            문짝
          </td>
          <td>
            <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            상태
          </td>
          <td>
            <select name='list22' onChange={onChange} value={idInform == null ? null : form.list22}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td rowSpan='2'>
            3. 소방호스
          </td>
          <td>
            수량
          </td>
          <td>
            <input type='text' name='list31' onChange={onChange} defaultValue={idInform == null ? null : form.list31} />
            
          </td>
        </tr>
        <tr>
          <td>
            상태
          </td>
          <td>
            <select name='list32' onChange={onChange} value={idInform == null ? null : form.list32}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td colspan='2'>
            4. 노즐
          </td>
          <td>
          <input type='text' name='list41' onChange={onChange} defaultValue={idInform == null ? null : form.list41} />
          
          </td>
        </tr>
        <tr>
          <td colspan='2'>
            5. 렌치
          </td>
          <td>
          <input type='text' name='list51' onChange={onChange} defaultValue={idInform == null ? null : form.list51}/>
            
          </td>
        </tr>
        
        <tr>
          <td colspan='2'>
            6. 특이사항
          </td>
          <td >
             <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment}/>
          </td>
        </tr>
        
      </table>
        
      )
  
    }else if(refParams.equipname == "모니터건"){
      if(refParams.division == "MMA2"){
        textHtml.push(<table>
          <tr>
            <td colSpan={2} >
              점검내용
            </td>
            <td>
              점검상태
            </td>
          </tr>
          <tr>
            <td rowSpan='2'>
              1. 소화전
            </td>
            <td>
              Leak 여부
            </td>
            <td>
              <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              동작(상하좌우, 노즐)
            </td>
            <td>
              <select name='list12' onChange={onChange} value={idInform == null ? null : form.list12}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
            
          </tr>
          <tr>
            <td rowSpan='2'>
              2. 소화전 Box
            </td>
            <td>
              문짝
            </td>
            <td>
              <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              상태
            </td>
            <td>
              <select name='list22' onChange={onChange} value={idInform == null ? null : form.list22}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td rowSpan='2'>
              3. 소방호스
            </td>
            <td>
              수량
            </td>
            <td>
            <input type='text' name='list31' onChange={onChange} defaultValue={idInform == null ? null : form.list31}/>
             
            </td>
          </tr>
          <tr>
            <td>
              상태
            </td>
            <td>
              <select name='list32' onChange={onChange} value={idInform == null ? null : form.list32}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colspan='2'>
              4. 노즐
            </td>
            <td>
            <input type='text' name='list41' onChange={onChange} defaultValue={idInform == null ? null : form.list41} />
             
            </td>
          </tr>
          <tr>
            <td colspan='2'>
              5. 렌치
            </td>
            <td>
            <input type='text' name='list51' onChange={onChange} defaultValue={idInform == null ? null : form.list51} />
              
            </td>
          </tr>
          
          <tr>
            <td colspan='2'>
              6. 특이사항
            </td>
            <td >
               <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment}/>
            </td>
          </tr>
          
        </table>)
      }else{
        textHtml.push(<table>
          <tr>
            <td>
              점검내용
            </td>
            <td>
              점검상태
            </td>
          </tr>
          <tr>
            <td>
              1. Leak 여부
            </td>
            <td>
              <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              2. 커넥터 상태
            </td>
            <td>
              <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              3. V 동작(상하)
            </td>
            <td>
              <select name='list31' onChange={onChange} value={idInform == null ? null : form.list31}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              4. V 동작(좌우)
            </td>
            <td>
              <select name='list41' onChange={onChange} value={idInform == null ? null : form.list41}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              5. 노즐동작
            </td>
            <td>
              <select name='list51' onChange={onChange} value={idInform == null ? null : form.list51}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              6. 표지판 상태
            </td>
            <td>
              <select name='list61' onChange={onChange} value={idInform == null ? null : form.list61}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              7. 기타 특이사항(불량사항 기입)
            </td>
            <td >
               <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment}/>
              
            </td>
          </tr>
          
        </table>
          
        )

      }
      
  
    }else if(refParams.equipname == "폼소화설비"){
      if(refParams.division == "MMA2"){
        textHtml.push(<table>
          <tr>
            <td colSpan={2} >
              점검내용
            </td>
            <td>
              점검상태
            </td>
          </tr>
          <tr>
            <td rowSpan='2'>
              1. 소화전
            </td>
            <td>
              Leak 여부
            </td>
            <td>
              <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11} >
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              커넥터상태
            </td>
            <td>
              <select name='list12' onChange={onChange} value={idInform == null ? null : form.list12} >
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
            
          </tr>
          <tr>
            <td rowSpan='2'>
              2. 소화전 Box
            </td>
            <td>
              문짝
            </td>
            <td>
              <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>
              상태
            </td>
            <td>
              <select name='list22' onChange={onChange} value={idInform == null ? null : form.list22}> 
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td rowSpan='2'>
              3. 소방호스
            </td>
            <td>
              수량
            </td>
            <td>
              <input type='text' name='list31' onChange={onChange} value={idInform == null ? null : form.list31}/>
              
            </td>
          </tr>
          <tr>
            <td>
              상태
            </td>
            <td>
              <select name='list32' onChange={onChange} value={idInform == null ? null : form.list32}>
                  <option value="양호">양호</option>
                  <option value="불량">불량</option>
                  <option value="해당없음">해당없음</option>
              </select>
            </td>
          </tr>
          <tr>
            <td colspan='2'>
              4. 노즐
            </td>
            <td>
            <input type='text' name='list41' onChange={onChange} defaultValue={idInform == null ? null : form.list41}/>
             
            </td>
          </tr>
          <tr>
            <td colspan='2'>
              5. 렌치
            </td>
            <td>
            <input type='text' name='list51' onChange={onChange} defaultValue={idInform == null ? null : form.list51}/>
              
            </td>
          </tr>
          
          <tr>
            <td colspan='2'>
              6. 특이사항
            </td>
            <td >
              <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment}/>
            </td>
          </tr>
          
        </table>
          
        )
        }else{
          textHtml.push(<table>
            <tr>
              <td colSpan={2} >
                점검내용
              </td>
              <td>
                점검상태
              </td>
            </tr>
            <tr>
              <td rowSpan='3'>
                1. Foam Tank
              </td>
              <td>
                Level 
              </td>
              <td>
              <input type='text' name='list11' onChange={onChange} defaultValue={idInform == null ? null : form.list11} />
               
              </td>
            </tr>
            <tr>
              <td>
                외관상태
              </td>
              <td>
                <select name='list12' onChange={onChange} value={idInform == null ? null : form.list12}>
                    <option value="양호">양호</option>
                    <option value="불량">불량</option>
                    <option value="해당없음">해당없음</option>
                </select>
              </td>
              
            </tr>
            <tr>
              <td>
                LG 상태
              </td>
              <td>
                <select name='list13' onChange={onChange} value={idInform == null ? null : form.list13}>
                    <option value="양호">양호</option>
                    <option value="불량">불량</option>
                    <option value="해당없음">해당없음</option>
                </select>
              </td>
              
            </tr>
            <tr>
              <td rowSpan='3'>
                2. 기타
              </td>
              <td>
                Valve Leak
              </td>
              <td>
                <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                    <option value="양호">양호</option>
                    <option value="불량">불량</option>
                    <option value="해당없음">해당없음</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                노즐
              </td>
              <td>
              <input type='text' name='list22' onChange={onChange} defaultValue={idInform == null ? null : form.list22}/>
               
              </td>
            </tr>
            <tr>
              <td>
                Hose
              </td>
              <td>
              <input type='text' name='list23' onChange={onChange} defaultValue={idInform == null ? null : form.list23}/>
                
              </td>
            </tr>
           
            
            <tr>
              <td >
                3. 특이사항
              </td>
              <td colspan="2">
                 <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment}/>
                
              </td>
            </tr>
            
          </table>
            
          )

        }
      
  
    }else if(refParams.equipname == "세안기"){
      textHtml.push(<table>
        <tr>
          <td>
            점검내용
          </td>
          <td>
            점검상태
          </td>
        </tr>
        <tr>
          <td>
            1. 보온상태
          </td>
          <td>
            <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            2. Leak/부식
          </td>
          <td>
            <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            3. Hand Valve 작동상태
          </td>
          <td>
            <select name='list31' onChange={onChange} value={idInform == null ? null : form.list31}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            4. Food Valve 작동상태
          </td>
          <td>
            <select name='list41' onChange={onChange} value={idInform == null ? null : form.list41}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            5. B/S 작동상태
          </td>
          <td>
            <select name='list51' onChange={onChange} value={idInform == null ? null : form.list51}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            6. 표지판 상태
          </td>
          <td>
            <select name='list61' onChange={onChange} value={idInform == null ? null : form.list61}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            7. 기타 특이사항
          </td>
          <td >
             <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment} />
          </td>
        </tr>
        
      </table>
        
      )
  
    }else if(refParams.equipname == "CO2"){
      textHtml.push(<table>
        <tr>
          <td>
            점검내용
          </td>
          <td>
            점검상태
          </td>
        </tr>
        <tr>
          <td>
            1. 용기저장 방화문 작동상태 및 시건장치 상태
          </td>
          <td>
            <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            2. CY'L 압력 (kg/cm2)
          </td>
          <td>
            <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            3. CY'L 충진상태
          </td>
          <td>
            <select name='list31' onChange={onChange} value={idInform == null ? null : form.list31}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            4. 수동조작 S/W 및 ABORT S/W 작동 상태
          </td>
          <td>
            <select name='list41' onChange={onChange} value={idInform == null ? null : form.list41}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            5. SIREN 설치수량 및 외관 상태
          </td>
          <td>
            <select name='list51' onChange={onChange} value={idInform == null ? null : form.list51}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            6. 감지기 설치 및 노후상태
          </td>
          <td>
            <select name='list61' onChange={onChange} value={idInform == null ? null : form.list61}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            7. 방출 표시등 점등 상태
          </td>
          <td>
            <select name='list71' onChange={onChange} value={idInform == null ? null : form.list71}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            8. 각 배관/Tube 조립상태
          </td>
          <td>
            <select name='list81' onChange={onChange} value={idInform == null ? null : form.list81}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            9. 분사노즐 막힘 및 장애물 유무
          </td>
          <td>
            <select name='list91' onChange={onChange} value={idInform == null ? null : form.list91}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            10. 안전밸브 부착 상태
          </td>
          <td>
          <select name='list101' onChange={onChange} value={idInform == null ? null : form.list101}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
            </td>
        </tr>
        <tr>
          <td>
            11. 방호구역 표시상태
          </td>
          <td>
            <select name='list111' onChange={onChange} value={idInform == null ? null : form.list111}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            12. Fan 가동 상태(Z-742)
          </td>
          <td>
            <select name='list121' onChange={onChange} value={idInform == null ? null : form.list121}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            13. CO2 Room Lighting 설비 상태
          </td>
          <td>
            <select name='list131' onChange={onChange} value={idInform == null ? null : form.list131}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            14. CO2 Room 내 정리정돈 상태
          </td>
          <td>
            <select name='list141' onChange={onChange} value={idInform == null ? null : form.list141}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
        <tr>
          <td>
            15. 기타 특이사항
          </td>
          <td >
             <input type='text' name='comment' onChange={onChange} defaultValue={idInform == null ? null : form.comment}/>
            
          </td>
        </tr>
        
      </table>
        
      )
  
    }else if(refParams.equipname == "스프링클러"){
      textHtml.push(<table>
        <tr>
          <td rowSpan='2' colspan='2'>
            점검내용
          </td>
          <td rowSpan='2'>
            정상위치
          </td>
          <td colSpan='2'>
            점검결과
          </td>
         
        </tr>
        <tr>
          <td >
            Warehouse
          </td>
          <td >
            Packing Area
          </td>
        </tr>
        <tr>
          <td rowSpan='2'>
            1. Pressure (kg/cm2)
          </td>
          <td>
            1차측
          </td>
          <td>
            10
          </td>
          <td>
           <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
          <td>
            <select name='list12' onChange={onChange} value={idInform == null ? null : form.list12}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
         
        </tr>
        <tr>
         
          <td>
            2차측
          </td>
          <td>
            0
          </td>
          <td>
            <select name='list21' onChange={onChange} value={idInform == null ? null : form.list21}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
          <td>
            <select name='list22' onChange={onChange} value={idInform == null ? null : form.list22 }>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
         
        </tr>
        <tr> 
         <td colspan='2'>
           2. 1, 2차측 Drain Valve 상태 
         </td>      
         
         <td>
           close
         </td>
         <td>
          <select name='list31' onChange={onChange} value={idInform == null ? null : form.list31}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list32' onChange={onChange} value={idInform == null ? null : form.list32}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
       
       </tr>
       <tr> 
        <td colspan='2'>
           3. Ball Drip V/V Drain 상태
         </td>      
         <td>
           close
         </td>
         <td>
          <select name='list41' onChange={onChange} value={idInform == null ? null : form.list41}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list42' onChange={onChange} value={idInform == null ? null : form.list42}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
       
         
       </tr>
       <tr> 
        <td colspan='2'>
           4. Emergency Valve 상태 (SOV)
         </td>      
         <td>
           close
         </td>
         <td>
          <select name='list51' onChange={onChange} value={idInform == null ? null : form.list51}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list52' onChange={onChange} value={idInform == null ? null : form.list52}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
       
         
       </tr>
       <tr> 
        <td colspan='2'>
           5. 1,2차측 PG Ball Valve 상태
         </td>      
         <td>
           close
         </td>
         <td>
          <select name='list61' onChange={onChange} value={idInform == null ? null : form.list61}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list62' onChange={onChange} value={idInform == null ? null : form.list62}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
       
         
       </tr>
       <tr> 
          <td colspan='2'>
           6. In/Out Main Valve 개방 상태
         </td>      
         <td>
           open
         </td>
         <td>
          <select name='list71' onChange={onChange} value={idInform == null ? null : form.list71}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list72' onChange={onChange} value={idInform == null ? null : form.list72}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
       
         
       </tr>
       <tr> 
          <td colspan='2'>
           7. 1차측 Service Valve 상태 (급수밸브)
         </td>      
         <td>
           close
         </td>
         <td>
          <select name='list81' onChange={onChange} value={idInform == null ? null : form.list81}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list82' onChange={onChange} value={idInform == null ? null : form.list82}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
       
         
       </tr>
       <tr> 
          <td colspan='2'>
           8. Insulation 상태
         </td>      
         <td>
          -
         </td>
         <td>
          <select name='list91' onChange={onChange} value={idInform == null ? null : form.list91}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list92' onChange={onChange} value={idInform == null ? null : form.list92}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
       
         
       </tr>
      
       <tr> 
          <td colspan='2'>
           9. Valve 및 Line 손상, 누수 상태
         </td>      
         <td>
          -
         </td>
         <td>
          <select name='list101' onChange={onChange} value={idInform == null ? null : form.list101}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list102' onChange={onChange} value={idInform == null ? null : form.list102}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         
       </tr>
       <tr> 
          <td colspan='2'>
           10. SOV 및 압력 S/W 배선 상태
         </td>      
         <td>
          -
         </td>
         <td>
          <select name='list111' onChange={onChange} value={idInform == null ? null : form.list111}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list112' onChange={onChange} value={idInform == null ? null : form.list112}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         
       </tr>
       <tr> 
          <td colspan='2'>
           11. 작동 방법 표지판 상태 
         </td>      
         <td>
          -
         </td>
         <td>
          <select name='list121' onChange={onChange} value={idInform == null ? null : form.list121}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list122' onChange={onChange} value={idInform == null ? null : form.list122}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         
       </tr>
       <tr> 
          <td colspan='2'>
           12. 송수구 및 Valve 주위 장애물 방치 상태 
         </td>      
         <td>
          -
         </td>
         <td>
          <select name='list131' onChange={onChange} value={idInform == null ? null : form.list131}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         <td>
          <select name='list132' onChange={onChange} value={idInform == null ? null : form.list132}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
         </td>
         
       </tr>
       
       
        <tr>
          <td>
            13. 기타 특이사항
          </td>
          
          <td colSpan='7'>

              {idInform == null ? <>
                  <CKEditor
          
                  editor={ ClassicEditor }
                  onChange={(event,editor)=>{
                      onChangeComment("comment",editor.getData())
                  }}
                  />
              
              </> : <>
              <CKEditor
                  data = {initComment !=null && initComment}
                  editor={ ClassicEditor }
                  onChange={(event,editor)=>{
                      onChangeComment("comment",editor.getData())
                      

                  }}
                />
              
              </>}

            
             
            
          </td>
        </tr>
        
      </table>
        
      )
  
    }else if(refParams.equipname == "FoamTank"){
      textHtml.push(<table>
        <tr>
          <td>
            점검내용
          </td>
          <td>
            점검상태
          </td>
        </tr>
        <tr>
          <td>
            1. 외관상태
          </td>
          <td>
            <select name='list11' onChange={onChange} value={idInform == null ? null : form.list11} >
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
           
          </td>        
        </tr>
        <tr>
          <td>
            2. Level(mm)
          </td>
          <td>
            <input type='text' name='list21' onChange={onChange} value={idInform == null ? null : form.list21} />
          </td>
        </tr>
        <tr>
          <td>
            3. LG 상태
          </td>
          <td>
            <select name='list31' onChange={onChange} value={idInform == null ? null : form.list31}>
                <option value="양호">양호</option>
                <option value="불량">불량</option>
                <option value="해당없음">해당없음</option>
            </select>
          </td>
        </tr>
       
        <tr>
          <td>
            기타 특이사항(불량사항 기입)
          </td>
          <td>
            <input type='text' name='comment' onChange={onChange} value={idInform == null ? null : form.comment}/>
          </td>
        </tr>
        
      </table>
        
      )
  
    }

  
  return textHtml

  
}
 

 

return(

  
        <Block>
            
            <table>
              <tr>
                <td colSpan={2}>
                  LOTTEMCC 소방설비 자체 점검<br />
                  {refParams.equipname} 점검표 ( {refParams.division} )
                </td>       
              </tr>
              <tr>
                <td >
                  점검일
                </td>
                <td>
                  <DatePicker style={{float:'left'}} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/>  
                </td>
              </tr>
              <tr>
                <td>
                  점검자
                </td>
                <td>
                  {datas == null ? 
                  <input type='text' name='writer' onChange={onChange} />:<>
                  {idInform != null && <input type='text' name='writer' onChange={onChange} defaultValue={datas.bodyData[0].writer} />} 
                  </>
                  
                }
                  
                </td>
              </tr>
              <tr>
                <td>
                  관리번호
                </td>
                <td>
                  {refParams.managenumber}
                </td>
              </tr>
              <tr>
                <td>
                  위치
                </td>
                <td>
                  [{refParams.location}] {refParams.dlocation}
                </td>
              </tr>
              <tr>
                <td>
                  제조년월
                </td>
                <td>
                  {limitDates !=null && <>{limitDates}</>}
                </td>
              </tr>
              
            </table>

            {View()}

            {idInform == null ? 
            <button onClick={()=>{doneWrite()}}>제출하기</button> : 
            <><button onClick={()=>{doneWrite()}}>수정하기</button> <br/>
            <button onClick={()=>{doneDelete()}}>삭제하기</button>
            </>
            

          }

          <p/>
            


            
                
                
          
        </Block>

    );
    
   
};

export default Write