import React from 'react';
import { useState, useCallback } from 'react';
import styled from 'styled-components'
import axios from 'axios';



import YeosuHoist from './Yeosu/Hoist';
import YeosuRobot from './Yeosu/Robot';
import YeosuConveyor from './Yeosu/Conveyor';
import YeosuCompressor from './Yeosu/Compressor';
import YeosuForklift from './Yeosu/Forklift';

import DaesanHoist from './Daesan/Hoist';
import DaesanRobot from './Daesan/Robot';
import DaesanConveyor from './Daesan/Conveyor';
import DaesanCompressor from './Daesan/Compressor';
import DaesanForklift from './Daesan/Forklift';



const Block = styled.div`
    
   

`



const index = ({match}) => {

    //console.log(match.params)
    const data = match.params
   
    const location = data.location
    const type = data.type
    //console.log(match.params.ver)
    //console.log(location)
        

    return(
            <>
              
                 <Block>
                 
                 {location == "yeosu" ? <>
                    
                    {type == "hoist" && <YeosuHoist data={match.params} />}
                    {type == "robot" && <YeosuRobot data={match.params} />}
                    {type == "conveyor" && <YeosuConveyor data={match.params} />}
                    {type == "compressor" && <YeosuCompressor data={match.params} />}
                    {type == "forklift" && <YeosuForklift data={match.params} />}

                  </>
                  : 
                  <>
                    {type == "hoist" && <DaesanHoist data={match.params} />}
                    {type == "robot" && <DaesanRobot data={match.params} />}
                    {type == "conveyor" && <DaesanConveyor data={match.params} />}
                    {type == "compressor" && <DaesanCompressor data={match.params} />}
                    {type == "forklift" && <DaesanForklift data={match.params} />}

                 </>
                 }
                 
                 
                 
                 </Block>

                             
              
            </>
    
        );
    
   
};

export default index