import React, { PureComponent } from 'react';
import { useState, useCallback, useEffect, useRef} from 'react';


import { useHistory } from 'react-router-dom'
import {useCookies} from 'react-cookie';


import styled from 'styled-components'
import axios from 'axios';

import SignatureCanvas from 'react-signature-canvas'
import {Buffer} from 'buffer'
import Modal from 'react-modal'
import {v4} from 'uuid'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"


import './Paging.css'
import Pagination from 'react-js-pagination';
import Write from './Write';

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



const Block = styled.div`
  overflow:auto;

  th, td {
    border: 1px solid lightgrey;
    padding: 3px;
    text-align: center ;
  }

`


const customStyles ={
  content: {
      top:'50%',
      left:'50%',
      right:'auto',
      bottom:'auto',
      marginRight:'-50%',
      transform: 'translate(-50%, -50%)',
      
  },
  border:{
    border: 'solid'
  }
  

 
  
}



Modal.setAppElement('#root')
const SubstationWrite = ({_id, location, refCategory}) => {
  const [datas, setDatas] =useState(null)
  const [refDatas, setRefDatas] =useState(null)

  const history = useHistory();
  const [cookie, setCookie, removeCookie] = useCookies(['auth']);
  const [modalIsOpen, setIsOpen] = useState(false);
  const signCanvas = useRef() 
  const componenetRef = useRef();
  

    //console.log(match.params.location)
    //const data = match.params
   
    //const location = data.location
    
    const [category, setCategory] = useState('elec')
    const [writeOn,setWriteOn] = useState(false)
    const [idInform,setIdInform] = useState(null)
    const [login, setLogin] = useState(true)

    const [ inChargePerson, setInChargePerson ] = useState('')
    const [startDate, setStartDate] = useState(new Date());
    const [selectDate, setSelectDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [signPath1, setSignPath1] = useState(null)
    const [signPath2, setSignPath2] = useState(null)
    const [signPath3, setSignPath3] = useState(null)
    const [detailView, setDetailView] = useState(false)


    const [filterOn, setFilterOn] = useState(false)
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pagelimit, setPagelimit] = useState(10);
    const handlePageChange = page => {
        
      setFilterOn(false)
      setPage(page)
      setDatas(refDatas.slice((page-1)*pagelimit,(page*pagelimit)))
               
  }

  const [form, setForm] = useState ({
    sub13 : "N/A",
    sub14 : "N/A",
    sub15 : "N/A",
    sub16 : "N/A",
    sub17 : "N/A",
    sub18 : "N/A",
    sub23 : "13",
    sub27 : "N/A",
    sub33 : "2000",
    sub37 : "N/A",
    sub43 : "2200",
    sub47 : "N/A",
    sub53 : "400",
    sub55 : "N/A",
    sub57 : "N/A",
    sub63 : "2200",
    sub67 : "N/A",
    sub73 : "2000",
    sub77 : "N/A",
    sub83 : "N/A",
    sub84 : "N/A",
    sub85 : "N/A",
    sub86 : "N/A",
    sub87 : "N/A",
    sub88 : "N/A",



  })

  const onChangeComment= useCallback((name,comment) => {
    
    //console.log(name)
    const nextForm = {
      ...form,
      [name]: comment
    };

    
    setForm(nextForm);
   
    //console.log(form)
    
},[form]);


  const today =(value) => {
    const year = value.getFullYear();
    const month = ('0' +(value.getMonth()+1)).slice(-2);
    const day = ('0' + value.getDate()).slice(-2);
    const dateString = year + '-' + month +'-' + day;

    return dateString
  }

  const dateFilter = useCallback(() => {
    const filterData = refDatas.filter(refData => {
      //console.log(refData.createdAt)
      //if(today( new Date(new Date(refData.createdAt) - 32400000)) >= today(startDate) && today(new Date(new Date(refData.createdAt) - 32400000)) <= today(endDate)){
      if(today( new Date(refData.createdAt)) >= today(startDate) && today(new Date(refData.createdAt)) <= today(endDate)){
        return true
      }
    })
    //console.log(startDate)
    
    //console.log(filterData)
    setDatas(filterData)
    setFilterOn(true);
  })

  

    const [ tableIndex, setTableIndex ] = useState(null)

 

    const onChange = useCallback(e => {
    
      
      const nextForm = {
        ...form,
        [e.target.name]: e.target.value
      };

      
      setForm(nextForm);
     
      
  },[form]);

  useEffect(() => {
    //console.log(cookies.auth)
    
    if (_id != null){
      //쓰기가 아닐경우 개별 아이디 조회 
      fetchGetData(_id);
      

    }
    
    //fetchLoginCheck();
    //fetchData();

  },[])

  



  const fetchGetData = async(_id) => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}`,{
                headers:{
                    token : cookie.auth
                }
            }
        );

        setDatas(response.data[0])
        setRefDatas(response.data[0])
        setTotalCount(response.data[0].length)
        setSelectDate(new Date(new Date(response.data[0].createdAt) - 32400000))
        setForm(response.data[0].dataList[0])
        //console.log(response.data[0])

       


    }catch (e) {
        //console.log(e);

       
    }
  }


  const fetchLoginCheck = async() => {

    try{
      
        const response = await axios.get(
            `${process.env.REACT_APP_HOST_RPA}/rpa/account/test`,{
                headers:{
                    token : cookie.auth
                }
            }
        );
        
            //console.log(response.data)
        
        if(response.data.code == 419 || response.data.code == 401)
        {
            //alert("토큰만료")
            history.push("/login")
        }

        //


    }catch (e) {
        //console.log(e);

        history.push("/login")
       
    }
  }



  
  

  const doneWrite = async() => {
    //alert("작성되었습니다.")
    
    
    
    if(window.confirm("제출하시겠습니까?")){
        
     
             //캔버스 저장 및 평가 결과 
            //save()           

          try{
      
              const response = await axios.post(
                `${process.env.REACT_APP_SOCKET}/plantdata?type=${refCategory}&date=${startDate}&location=${location}`,{
                    dataList : form,
                    headers:{
                        'token': cookie.auth
                    }
                });
                alert("등록되었습니다.")
                
                window.location.reload();
                
                //form.clear()
                
            
              //console.log(response.data.length)
      
             
      
      
          }catch (e) {
              //console.log(e);
              alert(e)
             
          }

            //fetchData()
            

            //setIsOpen(false)
            //fetchGetData()
            
            
            
    
    }else{
        //취소
        return false
    }
   
    
}

const doneMody = async() => {
  //alert("작성되었습니다.")
  
  
  
  if(window.confirm("수정할까요?")){
      
   
           //캔버스 저장 및 평가 결과 
          //save()           

        try{
    
            const response = await axios.put(
              `${process.env.REACT_APP_SOCKET}/plantdata/detail?id=${_id}&type=${refCategory}&date=${selectDate - 32400000 }&location=${location}`,{
                  dataList : form,
                  headers:{
                      'token': cookie.auth
                  }
              });
              alert("수정되었습니다..")
              
              window.location.reload();
              
              //form.clear()
              
          
            //console.log(response.data.length)
    
           
    
    
        }catch (e) {
            //console.log(e);
            alert(e)
           
        }

          //fetchData()
          

          //setIsOpen(false)
          //fetchGetData()
          
          
          
  
  }else{
      //취소
      return false
  }
 
  
}
//서명 클리어
const clear = () => {
    //캔버스 클리어
    signCanvas.current.clear();
}
//서명 저장
const save = () => {
    
    //console.log("캔버스 저장")
    
    const dataURL = signCanvas.current.toDataURL('image/png')
    const decodeURL = dataURL.replace(/^data:image\/\w+;base64,/,'');
    const buf = Buffer.from(decodeURL, 'base64')
    const blob = new Blob([buf], {type:'image/png'})
    const file = new File([blob], `${v4()}.png`,{type:'image/png'})
    //console.log(file)
    
    const formData = new FormData();
    formData.append('sign', file)

    /*
    axios({
        baseURL : process.env.REACT_APP_HOST,
        url: '/pm/uploadsignimg',
        method: 'POST',
        data: formData,
        headers:{
            'Content-Type' : 'multipart/form-data',              
        },
    })
        .then(response => {
            //console.log(response.data)
            //console.log(response.data.originalname)
            
            //setReturnOriginalFilename(response.data.originalname)
            //console.log(response.data.filename)
            if(inChargePerson == '담당자'){
              setSignPath1(response.data.filename)
            }else if(inChargePerson == '책임'){
              setSignPath2(response.data.filename)
            }else if(inChargePerson == '리더'){
              setSignPath3(response.data.filename)
            }
            //enrollResult(response.data.filename)

                           
            

        })
        .catch(error => {
            console.error(error)
        })

    */
   
    
}



  const openModal = async(refPerson) => {
    setIsOpen(!modalIsOpen)
    
    //console.log(refPerson)
    setInChargePerson(refPerson)

    
    
}



const afterOpenModal = () => {
   
}

const closeModal = () => {
    setIsOpen(false)
    
 
    //console.log("close")
}

 

    return(

      
            <Block>
               
                {_id == null ? <>
                  <div>
                        <table>
                         
                          <tr>
                            
                            <td colspan='2'> 
                            <div  style={{float:'left'}}>
                              <DatePicker style={{float:'left'}} selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="yy-MM-dd"/>  
                              </div>
                              <div  style={{float:'left'}}>
                              &emsp;&emsp; 날씨 : <input type='text' name="weather" onChange={onChange}></input> &emsp;&emsp; 대기온도 : <input type='text' name="temp" onChange={onChange}></input>℃ &emsp;&emsp;MCC ROOM 온도 : <input type='text' name="mccTemp" onChange={onChange}></input> ℃, &emsp;&emsp;UPS ROOM 온도  : <input type='text' name="upsTemp" onChange={onChange}></input> ℃
                              </div>
                              <div style={{float:'right'}}>
                                점검자 : <input type='text' name="name" onChange={onChange}></input>
                              </div>
                              
                            </td>
                            
                          </tr>
                          <tr>
                            <td>
                              상태표시
                            </td>
                            <td>
                              정상: ○, 요주의 ▲, 불량 : X , 이음 : N, 이취:R, 누유: L, 발열: H, 부족 : S, 진동 : V, 부족 : D, 기타 : E
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div>
                        <table>
                          <tr>
                            <td>
                              구분
                            </td>
                            <td colspan='2'>
                              SWGR & MCC
                            </td>
                            <td colspan='7'>
                              TRANSFORMER ( 33,000 / 6,600 V )
                            </td>
                            
                          </tr>
                          <tr>
                            <td>
                              FEEDER
                            </td>
                            <td>
                              SWGR & MCC <br/>
                              상태(ALARM)
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              CAPACITY <br/>
                              (MVA)
                            </td>
                            <td>
                              OIL TEMP
                            </td>
                            <td>
                              WINDING <br/>
                              TEMP
                            </td>
                            <td>
                              OIL LEVEL
                            </td>
                            <td>
                              N2 PRESSURE
                            </td>
                            <td>
                              TR BODY <br/>
                              STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                          </tr>
                          <tr>
                            <td>
                              INCOM'G (33KV)
                            </td>
                            <td>
                              <input type='text' name="sub11" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub12" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub13" defaultValue={form.sub13} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub14" defaultValue={form.sub14} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub15" defaultValue={form.sub15} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub16" defaultValue={form.sub16} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub17" defaultValue={form.sub17} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub18" defaultValue={form.sub18} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub19" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              INCOM'G (6.6KV)
                            </td>
                            <td>
                              <input type='text' name="sub21" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub22" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub23" defaultValue={form.sub23} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub24" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub25" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub26" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub27" defaultValue={form.sub27} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub28" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub29" onChange={onChange}></input>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div>
                        <table>
                          <tr>
                            <td>
                              구분
                            </td>
                            <td colspan='2'>
                              SWGR & MCC (480V )
                            </td>
                            <td colspan='7'>
                              TRANSFORMER ( 6600 / 480 V )
                            </td>
                            
                          </tr>
                          <tr>
                            <td>
                              FEEDER
                            </td>
                            <td>
                              SWGR & MCC <br/>
                              상태(ALARM)
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              CAPACITY <br/>
                              (MVA)
                            </td>
                            <td>
                              OIL TEMP
                            </td>
                            <td>
                              WINDING <br/>
                              TEMP
                            </td>
                            <td>
                              OIL LEVEL
                            </td>
                            <td>
                              N2 PRESSURE
                            </td>
                            <td>
                              TR BODY <br/>
                              STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-001
                            </td>
                            <td>
                              <input type='text' name="sub31" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub32" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub33" defaultValue={form.sub33} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub34" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub35" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub36" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub37" defaultValue={form.sub37} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub38" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub39" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-002
                            </td>
                            <td>
                              <input type='text' name="sub41" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub42" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub43" defaultValue={form.sub43} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub44" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub45" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub46" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub47" defaultValue={form.sub47} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub48" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub49" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-003
                            </td>
                            <td>
                              <input type='text' name="sub51" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub52" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub53" defaultValue={form.sub53} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub54" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub55" defaultValue={form.sub55} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub56" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub57" defaultValue={form.sub57} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub58" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub59" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-005
                            </td>
                            <td>
                              <input type='text' name="sub61" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub62" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub63" defaultValue={form.sub63} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub64" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub65" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub66" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub67" defaultValue={form.sub67} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub68" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub69" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-006
                            </td>
                            <td>
                              <input type='text' name="sub71" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub72" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub73" defaultValue={form.sub73} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub74" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub75" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub76" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub77" defaultValue={form.sub77} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub78" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub79" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              EMCC GROUP (004)
                            </td>
                            <td>
                              <input type='text' name="sub81" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub82" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub83" defaultValue={form.sub83} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub84" defaultValue={form.sub84} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub85" defaultValue={form.sub85} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub86" defaultValue={form.sub86} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub87" defaultValue={form.sub87} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub88" defaultValue={form.sub88} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub89" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              K6104 (고압 Inverter)
                            </td>
                            <td >
                              <input type='text' name="sub91" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub92" onChange={onChange}></input>
                            </td>
                            <td colSpan='7'>
                             <input type='text' name="sub93" onChange={onChange}></input>
                            </td>
                            
                          </tr>
                        </table>
                      </div>

                      <div>
                        <table>
                          <tr>
                            <th colspan='2'>
                              UPS
                            </th>
                            <th colspan='2'>
                              BATTERY CHARGER
                            </th>
                            <th colspan='2'>
                              VVVF & SOFT STARTER
                            </th>
                          </tr>
                          <tr>
                            <td>
                              ALARM STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              ALARM STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              ALARM STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type='text' name="sub101" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub102" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub103" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub104" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub105" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub106" onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              CAPACITOR BANK
                            </td>
                            <td>
                              DRY TR & MDB PANEL
                            </td>
                            <td>
                              CATHODIC PROTECTION PANEL
                            </td>
                            <td>
                              HRG PANEL
                            </td>
                            <td>
                              AIRCON 가동
                            </td>
                            <td>
                              충전식 비상전등
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type='text' name="sub111" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub112" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub113" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub114" onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub115" onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub116" onChange={onChange}></input>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div >
                        <CKEditor
                            
                            editor={ ClassicEditor }
                            onChange={(event,editor)=>{
                                onChangeComment("comment",editor.getData())
                            }}
                            />
                        <button onClick={() => doneWrite()}>등록하기</button>
                      </div>
                  
                </> : <>
                {datas != null &&<>
                <div>
                        <table>
                         
                          <tr>
                            
                            <td colspan='2'> 
                            <div  style={{float:'left'}}>
                             <DatePicker style={{float:'left'}} selected={selectDate} onChange={(date) => setSelectDate(date)} dateFormat="yy-MM-dd"/>  
                              
                              </div>
                              <div  style={{float:'left'}}>
                              &emsp;&emsp; 날씨 : <input type='text' name="weather" onChange={onChange} defaultValue={datas.dataList[0].weather}></input> &emsp;&emsp; 대기온도 : <input type='text' name="temp" defaultValue={datas.dataList[0].temp} onChange={onChange}></input>℃ &emsp;&emsp;MCC ROOM 온도 : <input type='text' name="mccTemp" defaultValue={datas.dataList[0].mccTemp} onChange={onChange}></input> ℃, &emsp;&emsp;UPS ROOM 온도  : <input type='text' name="upsTemp" defaultValue={datas.dataList[0].upsTemp} onChange={onChange}></input> ℃
                              </div>
                              <div style={{float:'right'}}>
                                점검자 : <input type='text' name="name" defaultValue={datas.dataList[0].name} onChange={onChange}></input>
                              </div>
                              
                            </td>
                            
                          </tr>
                          <tr>
                            <td>
                              상태표시
                            </td>
                            <td>
                              정상: ○, 요주의 ▲, 불량 : X , 이음 : N, 이취:R, 누유: L, 발열: H, 부족 : S, 진동 : V, 부족 : D, 기타 : E
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div>
                        <table>
                          <tr>
                            <td>
                              구분
                            </td>
                            <td colspan='2'>
                              SWGR & MCC
                            </td>
                            <td colspan='7'>
                              TRANSFORMER ( 33,000 / 6,600 V )
                            </td>
                            
                          </tr>
                          <tr>
                            <td>
                              FEEDER
                            </td>
                            <td>
                              SWGR & MCC <br/>
                              상태(ALARM)
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              CAPACITY <br/>
                              (MVA)
                            </td>
                            <td>
                              OIL TEMP
                            </td>
                            <td>
                              WINDING <br/>
                              TEMP
                            </td>
                            <td>
                              OIL LEVEL
                            </td>
                            <td>
                              N2 PRESSURE
                            </td>
                            <td>
                              TR BODY <br/>
                              STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                          </tr>
                          <tr>
                            <td>
                              INCOM'G (33KV)
                            </td>
                            <td>
                              <input type='text' name="sub11" defaultValue={datas.dataList[0].sub11} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub12" defaultValue={datas.dataList[0].sub12} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub13" defaultValue={datas.dataList[0].sub13} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub14" defaultValue={datas.dataList[0].sub14} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub15" defaultValue={datas.dataList[0].sub15} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub16" defaultValue={datas.dataList[0].sub16} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub17" defaultValue={datas.dataList[0].sub17} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub18" defaultValue={datas.dataList[0].sub18} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub19" defaultValue={datas.dataList[0].sub19} onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              INCOM'G (6.6KV)
                            </td>
                            <td>
                              <input type='text' name="sub21" defaultValue={datas.dataList[0].sub21} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub22" defaultValue={datas.dataList[0].sub22} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub23" defaultValue={datas.dataList[0].sub23} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub24" defaultValue={datas.dataList[0].sub24} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub25" defaultValue={datas.dataList[0].sub25} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub26" defaultValue={datas.dataList[0].sub26} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub27" defaultValue={datas.dataList[0].sub27} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub28" defaultValue={datas.dataList[0].sub28} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub29" defaultValue={datas.dataList[0].sub29}  onChange={onChange}></input>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div>
                        <table>
                          <tr>
                            <td>
                              구분
                            </td>
                            <td colspan='2'>
                              SWGR & MCC (480V )
                            </td>
                            <td colspan='7'>
                              TRANSFORMER ( 6600 / 480 V )
                            </td>
                            
                          </tr>
                          <tr>
                            <td>
                              FEEDER
                            </td>
                            <td>
                              SWGR & MCC <br/>
                              상태(ALARM)
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              CAPACITY <br/>
                              (MVA)
                            </td>
                            <td>
                              OIL TEMP
                            </td>
                            <td>
                              WINDING <br/>
                              TEMP
                            </td>
                            <td>
                              OIL LEVEL
                            </td>
                            <td>
                              N2 PRESSURE
                            </td>
                            <td>
                              TR BODY <br/>
                              STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-001
                            </td>
                            <td>
                              <input type='text' name="sub31" defaultValue={datas.dataList[0].sub31} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub32" defaultValue={datas.dataList[0].sub32} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub33" defaultValue={datas.dataList[0].sub33}  onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub34" defaultValue={datas.dataList[0].sub34} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub35" defaultValue={datas.dataList[0].sub35} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub36" defaultValue={datas.dataList[0].sub36} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub37" defaultValue={datas.dataList[0].sub37} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub38" defaultValue={datas.dataList[0].sub38} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub39" defaultValue={datas.dataList[0].sub39} onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-002
                            </td>
                            <td>
                              <input type='text' name="sub41" defaultValue={datas.dataList[0].sub41} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub42" defaultValue={datas.dataList[0].sub42} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub43" defaultValue={datas.dataList[0].sub43} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub44" defaultValue={datas.dataList[0].sub44} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub45" defaultValue={datas.dataList[0].sub45} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub46" defaultValue={datas.dataList[0].sub46} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub47" defaultValue={datas.dataList[0].sub47} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub48" defaultValue={datas.dataList[0].sub48} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub49" defaultValue={datas.dataList[0].sub49} onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-003
                            </td>
                            <td>
                              <input type='text' name="sub51" defaultValue={datas.dataList[0].sub51} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub52" defaultValue={datas.dataList[0].sub52} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub53"  defaultValue={datas.dataList[0].sub53} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub54" defaultValue={datas.dataList[0].sub54}  onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub55" defaultValue={datas.dataList[0].sub55} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub56" defaultValue={datas.dataList[0].sub56}onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub57" defaultValue={datas.dataList[0].sub57} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub58" defaultValue={datas.dataList[0].sub58} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub59" defaultValue={datas.dataList[0].sub59}  onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-005
                            </td>
                            <td>
                              <input type='text' name="sub61" defaultValue={datas.dataList[0].sub61} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub62" defaultValue={datas.dataList[0].sub62} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub63" defaultValue={datas.dataList[0].sub63} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub64" defaultValue={datas.dataList[0].sub64} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub65" defaultValue={datas.dataList[0].sub65} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub66" defaultValue={datas.dataList[0].sub66} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub67" defaultValue={datas.dataList[0].sub67} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub68" defaultValue={datas.dataList[0].sub68} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub69" defaultValue={datas.dataList[0].sub69} onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              MMA-52-LSWG-006
                            </td>
                            <td>
                              <input type='text' name="sub71" defaultValue={datas.dataList[0].sub71} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub72" defaultValue={datas.dataList[0].sub72} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub73" defaultValue={datas.dataList[0].sub73} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub74" defaultValue={datas.dataList[0].sub74} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub75" defaultValue={datas.dataList[0].sub75} nChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub76" defaultValue={datas.dataList[0].sub76} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub77" defaultValue={datas.dataList[0].sub77} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub78" defaultValue={datas.dataList[0].sub78} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub79" defaultValue={datas.dataList[0].sub79} onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              EMCC GROUP (004)
                            </td>
                            <td>
                              <input type='text' name="sub81" defaultValue={datas.dataList[0].sub81} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub82" defaultValue={datas.dataList[0].sub82} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub83" defaultValue={datas.dataList[0].sub83} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub84" defaultValue={datas.dataList[0].sub84} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub85" defaultValue={datas.dataList[0].sub85} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub86" defaultValue={datas.dataList[0].sub86} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub87" defaultValue={datas.dataList[0].sub87} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub88" defaultValue={datas.dataList[0].sub88} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub89" defaultValue={datas.dataList[0].sub89} onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              K6104 (고압 Inverter)
                            </td>
                            <td>
                              <input type='text' name="sub91" defaultValue={datas.dataList[0].sub91} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub92" defaultValue={datas.dataList[0].sub92} onChange={onChange}></input>
                            </td>
                            <td colSpan='7'>
                             <input type='text' name="sub93" defaultValue={datas.dataList[0].sub93} onChange={onChange}></input>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div>
                        <table>
                          <tr>
                            <th colspan='2'>
                              UPS
                            </th>
                            <th colspan='2'>
                              BATTERY CHARGER
                            </th>
                            <th colspan='2'>
                              VVVF & SOFT STARTER
                            </th>
                          </tr>
                          <tr>
                            <td>
                              ALARM STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              ALARM STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                            <td>
                              ALARM STATUS
                            </td>
                            <td>
                              상세사항
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type='text' name="sub101" defaultValue={datas.dataList[0].sub101} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub102" defaultValue={datas.dataList[0].sub102} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub103" defaultValue={datas.dataList[0].sub103} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub104" defaultValue={datas.dataList[0].sub104} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub105" defaultValue={datas.dataList[0].sub105} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub106" defaultValue={datas.dataList[0].sub106} onChange={onChange}></input>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              CAPACITOR BANK
                            </td>
                            <td>
                              DRY TR & MDB PANEL
                            </td>
                            <td>
                              CATHODIC PROTECTION PANEL
                            </td>
                            <td>
                              HRG PANEL
                            </td>
                            <td>
                              AIRCON 가동
                            </td>
                            <td>
                              충전식 비상전등
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input type='text' name="sub111" defaultValue={datas.dataList[0].sub111} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub112" defaultValue={datas.dataList[0].sub112} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub113" defaultValue={datas.dataList[0].sub113} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub114" defaultValue={datas.dataList[0].sub114} onChange={onChange}></input>
                            </td>
                            <td>
                             <input type='text' name="sub115" defaultValue={datas.dataList[0].sub115} onChange={onChange}></input>
                            </td>
                            <td>
                              <input type='text' name="sub116" defaultValue={datas.dataList[0].sub116} onChange={onChange}></input>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div >
                        <CKEditor
                            
                            editor={ ClassicEditor }
                            onChange={(event,editor)=>{
                                onChangeComment("comment",editor.getData())
                            }}
                            />
                        <button onClick={() => doneMody()}>수정하기</button>
                      </div>
                
                </>}
                </>}
                      
                    
                    
                    
             
                             
              
            </Block>
    
        );
    
   
};

export default SubstationWrite